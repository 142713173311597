<template>
  <div
    class="video-container"
    :class="{ 'video-container-rounded': rounded }"
    @click="paused = false"
  >
    <ImageContainer
      :fileName="thumbnailFileName"
      background
      :size="size"
      v-if="paused"
      class="thumbnail"
    >
      <img src="/imgs/svg/icon_play.svg" alt="play icon" />
    </ImageContainer>
    <iframe
      v-else
      width="100%"
      height="100%"
      :src="`https://www.youtube.com/embed/${videoToken}`"
      frameborder="0"
      allowfullscreen
    />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    thumbnailFileName: {
      type: String,
      default: null,
    },
    videoToken: {
      type: String,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "300px",
    },
  },
  data: () => ({
    paused: true,
  }),
};
</script>

<style></style>
