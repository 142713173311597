export default {
  certifications: {
    title: "Магадлан итгэмжлэл",
    text: "NED коллеж нь оюутнуудынхаа хайж буй өндөр чанарын стандартыг баталгаажуулахын тулд их сургуулиудтай холбоотой байдаг",
  },
  studywithus: {
    header: {
      title: `<span class="text-white">Ирландад NED College-той хамт суралцангаа ажиллаарай</span>`,
      subtitle: `Анхны алхамаа одоо хийгээрэй!`,
    },
    form: {
      title: "Анхны алхамаа одоо хийгээрэй!",
      name: `Бүрэн нэр`,
      email: `Шулуун цахилгаан`,
      whatsapp: `WhatsApp`,
      nationality: `Сонголтыг үндэсний хэмжээнд сонгох хэрэгтэй`,
      campus: `Сургуулийн оюутны хотхоны талаар юу хэлэх вэ?`,
      button: `Үнийн санал авах!`,
    },
    section1: {
      title: `Яагаад Ирланд улсыг суралцах хөтөлбөрөөр сонгох хэрэгтэй вэ?`,
      items: [
        "Хууль ёсны дагуу суралцах, ажиллах боломж",
        "Европ дахь хамгийн өндөр цалингийн нэг",
        "Ирландад шууд ирсний дараа виз авах хялбар процесс",
        "Европын янз бүрийн орноор аялах боломж",
      ],
    },
    section2: {
      title: `NED College маань 10 гаруй жилийн турш Англи хэлний сургуулиар үйл ажиллагаагаа явуулж байгаа ба Ирланд улсад 30аад гаруй орны иргэдийг суралцах хөтөлбөрөөр авчирсаар байгаа билээ.`,
      subtitle: `Улсын хэмжэнд 2 кампустай ба Бид оюутнууддаа ер бусын туршлагыг өгч, маш сайн боловсролыг санал болгодог`,
      button: "Би NED коллежид сурмаар байна",
    },
    section3: {
      dublin: {
        title: "Dublin кампус",
        subtitle:
          "Хотын зүрхэн хэсэгт буюу хотын төвдөө байрлалтай, олон нийтийн тээврийн хэрэгсэлтэйгээ ойрхон, 1750 онд баригдсан түүхэн 2 барилгатай",
        button: "Би Дублинд суралцахыг хүсч байна",
      },
      limerick: {
        title: "Лимерик кампус",
        subtitle:
          "Лимерик хотын төвд байрладаг, хотын аялал жуулчлалын гол газрууд руу алхахад ойрхон, орчин үеийн тоног төхөөрөмжтэй барилгад байрладаг байгаа.",
        button: "Би Лимерикт суралцах хүсэлтэй байна.",
      },
    },
    section4: {
      title: "Та яагаад Ирланд дахь суралцах хөтөлбөртөө NED коллежийг сонгох ёстой вэ?",
      items: [
        {
          img: "/landings/landing_0823/imgs/services_01.png",
          text: "Тавтай морилно уу багц,  уулзалт, аялал",
        },
        {
          img: "/landings/landing_0823/imgs/services_02.png",
          text: "Соёлын үйл ажиллагаа",
        },
        {
          img: "/landings/landing_0823/imgs/services_03.png",
          text: "Эх хэлээрээ мэдээлэл авах дэмжлэг",
        },
        {
          img: "/landings/landing_0823/imgs/services_04.png",
          text: "Аялал зугаалга",
        },
        {
          img: "/landings/landing_0823/imgs/services_05.jpeg",
          text: "Мэргэшсэн багш нар",
        },
        {
          img: "/landings/landing_0823/imgs/services_06.jpeg",
          text: "Хичээлээс гадуурх хичээлүүд",
          description: "(Харилцан яриа, дуудлага гэх мэт)",
        },
        {
          img: "/landings/landing_0823/imgs/services_07.jpeg",
          text: "Семинар, мэргэжлийн курсууд",
        },
        {
          img: "/landings/landing_0823/imgs/services_08.jpeg",
          text: "Ирланд дахь оюутны дэмжлэг",
        },
        {
          img: "/landings/landing_0823/imgs/services_09.jpeg",
          text: "Төлбөрийн уян хатан сонголт",
        },
        {
          img: "/landings/landing_0823/imgs/services_10.png",
          text: "NED коллежийн онцгой програм",
        },
        {
          img: "/landings/landing_0823/imgs/services_11.png",
          text: "Хамтрагч компаниудын хөнгөлөлт",
        },
      ],
      button: "Би NED коллежид сурмаар байна",
    },
    section5: {
      title: "Манай оюутнууд юу хэлж байна вэ?",
      button: "Үнийн санал авах!",
    },
    section6: {
      button: "Би NED коллежид сурмаар байна",
    },
  },
  studywithus_thanks: {
    title: `NED коллежийг сонирхож байгаад баярлалаа!`,
    subtitle: `Бид таны мэдээллийг хүлээн авсан тул удахгүй тантай холбогдох болно.`,
    description: `Одоо та эхний алхмыг хийсэн тул манай англи хэлний сургуульд илүү гүнзгий нэвтэрч, NED оюутны амьдралыг олж мэдэхийг бид танд зөвлөж байна.`,
    socials_title: "Бидний social media хуудсуудыг дагаарай!",
  },
  financial_plan: {
    title: `Ирландад сурчихахад <span class="text-green3">Бүтэн Санхүүжилтийн Төслийн Гуйд</span> эхлэнэ үү`,
    welcome: {
      title: "Ирландд сурчихахад Санхүүжилтийн Төсөл Гуйд руу тавтай морилно уу!",
      subtitle: "Цэцэрлэгт Имээгээр зориулсан харилцаа? Тэгээд энэ электрон ном та бол таны байна.",
      text: "Бид эдгээр санхүүжилтийн хэсгийг байхгүйгээр өртсөн байна, тиймээс бид таны мөнгөний сургалтыг хүргэх бүрэн төсөл хэрэглэнэ.",
    },
    why: {
      title: "Энэ зааврыг татаж авч байхын тулд юу болох вэ?",
      items: [
        {
          icon: "/landings/financial_plan/icon_1.png",
          text: 'Таны <span class="text-green3">цэцэрлэгийн зорилгуудыг тодорхойлон</span> ба хамаарах үнийг ойлго.',
        },
        {
          icon: "/landings/financial_plan/icon_2.png",
          text: 'Өөрийн үнэт зүйлт хуваарийг үүсгэх <span class="text-green3">орлого зардалтанд нийцтэй</span> байдлаар ойлго.',
        },
        {
          icon: "/landings/financial_plan/icon_3.png",
          text: 'Цэцэрлэгийн өмнө ба цэцэрлэгийн хугацаанд таны мөнгөнийг <span class="text-green3">хэвийн болгох болон удирдах</span> сургалт.',
        },
        {
          icon: "/landings/financial_plan/icon_4.png",
          text: 'Таны цэцэрлэгийн хугацаанд <span class="text-green3">ажил олохыг хайж байна</span>, танд зөвлөх болно.',
        },
        {
          icon: "/landings/financial_plan/icon_5.png",
          text: 'Танд орж болох боломжтой <span class="text-green3">хэрэгцээгүй болон мөнгөн засварын</span> зөвлөгөө авах.',
        },
      ],
    },
    cta: "Энд татаж аваарай!",
    cta_title:
      'Одоо татах <span class="text-blue2">Ирланд сургуулийн санхүүжилтийн төслийн гуйг</span> эхлүүлээд санаач!',
    footer: {
      campus: "Дублин болон Лимерик",
    },
    form: {
      title: "Имэйл хаяг руу электрон номыг авахын тулд доорх хэсэгт мэдээллийг бөглөнө үү",
      name: `Бүтэн нэр`,
      email: `Имэйл хаяг`,
      whatsapp: `Phone number/WeChat/Line`,
      nationality: `Өмгөөлөгчийн улс сонгоно уу`,
      campus: `Та ямар сургуульд суралцахыг хүсэж байна уу?`,
      mkt_source: `Та бидний номоо ямар зүйлийн тусламжтай болгох гэсэн юу хэлсэн бэ?`,
      button: `Миний номыг одоо татаж авах!`,
    },
    ebook_thumb: "/landings/financial_plan/ebook-thumb-mn.png",
  },
  financial_plan_thanks: {
    title: `Ирланд сурчихахад санхүүжилтийн төслийн гуйг татаж авч байгаад баярлалаа!`,
    first_step: {
      title: 'Та өөрийн алсын өмнө өөртөө хөгжилтэй хүмүүсийн сайн туршлагтай хамт болсно гэдгийг бүтэн сэтгэлтэй байна!',
      subtitle: 'Бидний зөвлөгөөнүүдийг ашиглан, танд танай амьдралын хамгийн сайхан туршлагыг бэлтгэж эхэлээрэй.',
      bold: 'Энэ замд таны тусламж хэрэгтэй байна!',
    },
    socials: {
      title: 'Бидний сургуулийн өдөр тутмын амьдралыг дагнан таньдаг байна, бидний оюутнуудын амьдралын явцыг ойлгож чадах болно!',
      subtitle: 'Сошиал медиа дээр бидэнд дагаарай!',
    },
    footer: {
      campus: "Дублин болон Лимерик",
    },
    ebook_thumb: "/landings/financial_plan/ebook-thumb-mn.png",
  }
};
