export default {
  // pages
  home: {
    header: {
      title1: "Study English in Ireland",
      title2: "with NED College",
      subtitle: "English courses made for you!",
    },
    section1: {
      title1: "A new jurney is about to begin",
      title2:
        "Established in 2010, this English School has two campuses in Ireland: Dublin and Limerick",
      title3:
        "We are truly honoured to say that we have helped more than 13.000 students over 40 different nationalities around the globe to learn English, while having amazing experiences in Ireland.",
      button: "I want to start my new journey",
    },
    splitted_section: {
      title1: "Dublin Campus",
      text1:
        "The school is located in the heart of the city, accessed via multiple public transport services or by foot, inside two beautiful historical buildings, built-in 1750!",
      button1: "I want to know more",
      title2: "Limerick Campus",
      text2:
        "Our second Campus is also located in the heart of the city in Limerick, in a beautiful and modern building, with completely new and modern facilities!",
      button2: "I want to know more",
    },
    section_courses: {
      title: "Check out our English courses",
      text: "Choose the course that best suits you and start your journey with us!",
      button: "Discover all courses",
      first_card_title: "Long-term English Course",
      first_card_text:
        "The most loved and chosen course by students! This program will give you the opportunity to have a visa that allows you to study and also work legally in for 8 months.",
      second_card_title: "Short-term English Course",
      second_card_text:
        "Ideal for students who want to enhance and practise the language with the perfect surrounding in a short time.",
      third_card_title: "General English + IELTS",
      third_card_text:
        " IELTS Preparation Course The International English Language Testing System (IELTS) is designed to help you work, study or migrate to a country where English is the native language.",
    },
    studentlife: {
      title: "Student Life",
      text: "Our school offers much more than English courses, we offer the whole experience!",
      activities1: "Activities, excursions",
      activities2: "and",
      activities3: "Extracurricular classes",
      app: "Exclusive App",
      app2: "for NED College students",
      accomodation1: "Temporary Accommodation",
      accomodation2: " in Ireland",
      airport1: "Airport ",
      airport2: "pick-up",
      button: "More about our services",
    },
    modal: {
      image: "",
      imageMobile: "/",
    },
  },
  ned_college: {
    title: "<span class='text-green2'>English School</span> in Ireland",
    breadcr: ["About Us", ">", `NED College`],
    section1: {
      title1: "NED College is an English",
      title2: " Language School based in Ireland",
      text1:
        "Since 2010, we have been helping students from all over the world to improve their English, connect with new people and a new world, offering the best quality learning while having an amazing experience in Ireland.",
      text2:
        "For more than 10 years, our team has been working hard to provide the best for our students, offering them much more than just English classes: an UNFORGETTABLE EXPERIENCE!",
      text3:
        "Currently NED College has two campuses in the country; one in Dublin and one in Limerick, and in each of them, you will find the ideal facilities to ensure you have the best educational experience abroad.",
    },
    section2: {
      title: "Welcoming students from all around the world",
      text: "A multicultural environment, with different people, cultures, and backgrounds, is the best way to learn/improve a new language. At NED College, we have been investing in this principle since 2010. We have helped more than 12 thousand students from over 30 countries improve their English, as well as enjoying an experience that they will never forget.",
    },
    section3: {
      title1: "If studying abroad has always been one of your dreams,",
      title2: "you are in the right place to make it a reality!",
      button: "I want to live my dream",
    },
    section4: {
      title: "Why choose NED College?",
      text1: "+10 years in the field of education",
      text2: "Student support in your own language",
      text3: "+13.000 people have studied with us",
      text4: "Over 40 nationalities",
      text5: "Two campuses: Dublin and Limerick",
      text6: "Fully qualified teachers",
      button: "Start your journey in Ireland with NED College!",
    },
    section5: {
      title:
        "All of our teachers hold a minimum of a primary degree and a recognized English Language Teaching qualification.",
      text: "All of our teachers are proffesionals. Many have taught not only in Ireland but also overseas and bring a wealth of experience to the classroom to ensure that your time with us is educational, engaging and fun.",
    },
    section6: {
      title1: "Dublin Campus",
      text1:
        "Located in the heart of the city, inside two beautiful historical buildings, built-in 1750. This location has been the home of NED College for more than 10 years!",
      button1: "I choose Dublin!",
      circle1: "Choosing",
      circle2: "between",
      title2: "Limerick Campus",
      text2:
        " Our second Campus is located in the heart of Limerick city, in a beautiful building, with completely new and modern facilities!",
      button2: "I choose Limerick!",
    },
    section7: {
      title: "NED College Mission, Ethos & Values",
      subtitle:
        "Senior management and company directors have redefined our mission statement after a period of consultation to better reflect our values.",
      subtitle1: "Mission Statement",
      text1:
        "Our mission is to be the first choice for English language students by providing a high quality learning experience and excellence in language teaching. Our staff work in partnership, where the needs of our students, who are at the heart of our daily actions for             the development of the NED College community as a whole.",
      text2:
        "We strive to deliver an approach to English language education that reflects and caters to the needs of our diverse student community, creating a positive and fun learning environment, underpinned by best practice, that is inclusive and supportive, ensuring our students meet their academic goals.",
      subtitle2: "Ethos",
      text3:
        "NED College's ethos is underpinned by our core values of community, individual significance, group solidarity, diversity, excellence in teaching and respect for all. Our staff work in partnership to build rapport with each student, developing a sense of individual significance and solidarity within our College community inside and outside the classroom.",
      text4:
        "We provide focused training and development to ensure we are delivering excellence in teaching, in compliance with legislation and required standards, in line with best practice in the industry. We believe in treating everyone fairly and ethically and this is reflected in our ethos logo.",
      subtitle3: "To this end, NED College is committed to:",
      list1: "Promoting equality for all students",
      list2: "Assessing students in a fair and consistent manner",
      list3:
        "Providing opportunities for feedback and deal with any issues in a professional and timely manner",
      list4:
        "Adhering to applicable guidelines and legislative obligations and implement recommendations and directives as forthcoming.",
    },
    section8: {
      text1:
        "NED College provides a culturally immersive experience where students are supported on all levels,",
      text2:
        "embracing diversity and assisting students to integrate into Irish society to get the most from their learning experience.",
    },
    testimonial: {
      title: "Check out what out students are saying about NED College",
    },
  },
  courses: {
    title: "English Courses Ireland",
    breadcr: ["Courses"],
    section1: {
      title1: "Maximize your potential with our adult English Courses",
      subtitle1:
        "At NED College we want to give you the best possible opportunity whether you are learning to further your career, to develop your own personal skills or to travel with confidence.",
      title2: "General English Courses",
      text1: "If you would like to experience a new",
      text2: "cultural experience",
      text3:
        "and reach the best possible level with intensive classes, this course is for you! All",
      text4: "adult classes",
      text5: "are given 5 days a week, from Monday to Friday with the",
      text6: "best experienced English teachers.",
      cards: [
        {
          color: "blue2",
          tag: "25-weeks",
          title: "General English Course Long-term",
          bodyTitle: "",
          body: `
          Take your English skills to the next level and enjoy this experience with us! To take
          this course, most travellers need to apply for a Student Visa, which allows them to
          work and study legally in Ireland while they are here. Additionally, students can take
          certificate exams at the end of their course; such as TIE, IELTS, Cambridge and
          Trinity Exams.
          `,
          image: null,
          button: `I want a quotation!`,
        },
        {
          color: "blue3",
          tag: "4, 8 and 12-weeks",
          title: "General English Course Short-term",
          bodyTitle: "",
          body: `
          Ideal for students who want to enhance and practise the language with the perfect
          surrounding in a short time.
          `,
          image: null,
          button: `I want a quotation!`,
        },
        {
          color: "green2",
          tag: "4, 8 and 12-weeks",
          title: "General English Course Winter program",
          bodyTitle: "October, November, January and February",
          body: `
          This program is recommended for those who are not able to spend a long period in
          Ireland and aim to improve their English skills as well as enjoy some time in
          Ireland.
          `,
          image: null,
          button: `I want a quotation!`,
        },
        {
          color: "green1",
          tag: "4, 8 and 12-weeks",
          title: "General English Course Summer Programme",
          bodyTitle: "June, July and August",
          body: `
          This program is recommended for those who are not able to spend a long period in
          Ireland and aim to improve their English skills as well as enjoy summer in Ireland.
          `,
          image: null,
          button: `I want a quotation!`,
        },
        {
          color: "red",
          tag: "25-weeks",
          title: "General English + IELTS Preparation Course",
          bodyTitle: "",
          body: `
          The International English Language Testing System (IELTS) is designed to help you work, study or migrate to a country where English is the native language.
          `,
          image: "logo_ielts",
          button: `I want a quotation!`,
        },
      ],
    },
    section2: {
      title: "Who have a good level of ability",
      text1: `NED College has courses available for all English levels, from students who have little
            or no English, to those who have a good level but want to continue to improve it. On the
            right, you can find a list of the different levels and the type of certificate exam that
            we recommend you do at the end of your course.`,
      text2: "Level",
      text3: "Type of Exam (recommended)",
      accordion_data: [
        {
          title: "A0 - A1 | Beginner",
          tag: "TIE",
          body: [
            {
              type: "p",
              class: "fw-bold text-blue2",
              text: "Course Outline and objectives",
            },
            {
              type: "p",
              class: "",
              text: "The Pre A1 General English course is aimed at learners with very little or no knowledge or experience of the English language to begin to develop all four skills: reading, writing, speaking and listening. This course is designed to develop basic English skills and build confidence to engage with native and non-native interlocutors in the target language on a range of familiar subjects using everyday vocabulary and simple grammatical structures. It is the first step on the road to communicative efficiency. Ultimately, the aim of the A0 course is for learners to progress to the A1 course where they can further develop and consolidate their abilities in the language and at which level they will be eligible to take an externally assessed examination. Over the period of eleven weeks, students will be expected to cover the language areas and fulfil the outcomes listed below:",
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "The following competencies are assigned to the Pre A1 course, by the end of which students will be able to demonstrate the following skills:",
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "Receptive Skills",
            },
            {
              type: "ul",
              list: [
                "Interact in a simple way provided the other person is prepared to repeat or rephrase things at a slower rate of speech and help with formulation.",
                "Understand the general idea of simple informational texts and short simple descriptions, especially if they contain pictures which help to explain the text.",
                "Understand very short, simple texts, putting together familiar names, words and basic phrases, by, for example, rereading parts of the text.",
                "Understand short, simple messages, e.g. on postcards",
                "Understand everyday expressions dealing with simple and concrete everyday needs, in clear, slow and repeated speech.",
                "Understand questions and instructions and follow short, simple directions.",
                "Understand numbers, prices and times.",
                "Follow short, simple written instructions, especially if they contain pictures.",
                "Recognise familiar names, words and very basic phrases on simple notices in the classroom and around the school.",
                "Follow speech which is very slow and carefully articulated, with long pauses for them to get the meaning",
              ],
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "Productive Skills",
            },
            {
              type: "ul",
              list: [
                "Use simple phrases and sentences to describe where they live and people they know.",
                "Ask and answer simple questions in areas of immediate need or on very familiar topics.",
                "Establish basic social contact by using the simplest everyday polite forms of: greetings and farewells; introductions; saying please, thank you, sorry, etc.",
                "Write a short, simple message or postcard, for example sending holiday greetings.",
                "Fill in forms with personal details, for example entering name, nationality and address on a hotel registration form.",
                "Write simple isolated phrases and sentences and can write a short simple postcard. They can write short letters and messages with the help of a dictionary.",
              ],
            },
          ],
          footer: [
            {
              type: "p",
              class: "fw-bold text-blue2",
              text: "Exam preparation",
            },
            {
              type: "p",
              class: "",
              text: "There is no exit examination associated with level Pre A1. Students are not expected to spend more than 11 weeks of their course at this level. On progressing to A1 they will become eligible for Test of Interactive English.",
            },
          ],
        },
        {
          title: "A1 - A2 | Elementary",
          tag: "TIE",
          body: [
            {
              type: "p",
              class: "text-blue2",
              text: "Course Outline and objectives",
            },
            {
              type: "p",
              class: "",
              text: `The A1 General English course is aimed at learners who come to NED C with a
              basic familiarity with English, and may have had some informal experience of
              learning English and contact with the language either in an educational, social
              or recreational setting. This might not be a learner's first course of recent
              study. However, as outlined above, each course is structured to enable a student
              to remain at the designated level for as long as they need up to the 25 week
              duration. Over the period of twenty five weeks, students will be expected to
              cover the language areas below:`,
            },
            {
              type: "p",
              class: "text-green1",
              text: `The following learning outcomes are assigned to the A1
              (elementary) course, by the end of which students will be able to:`,
            },
            {
              type: "p",
              class: "text-green1",
              text: `Receptive Skills`,
            },
            {
              type: "ul",
              list: [
                "Understand clear, slow, standard speech related to areas of most immediate personal relevance (e.g. very basic personal and family information, shopping, local geography and employment)",
                "Catch the main point in short, clear, simple messages and announcements.",
                "Understand short, simple texts containing high frequency vocabulary and shared international expressions.",
                "Find specific, predictable information in simple everyday material such as advertisements, prospectuses and timetables.",
                "Understand everyday signs and notices in public places, such as streets, restaurants, railway stations and in workplaces.",
              ],
            },
            {
              type: "p",
              class: "text-green1",
              text: `Productive Skills`,
            },
            {
              type: "ul",
              list: [
                "Communicate in simple and routine tasks requiring a simple and direct exchange of information on familiar topics and activities.",
                "Handle very short social exchanges, even though they cannot usually keep the conversation going by themselves.",
                "Have sufficient vocabulary for the expression of basic communicative needs and for coping with simple survival needs.",
                "Use some simple structures correctly, but still systematically make basic mistakes such as tense confusion and forget to mark agreement.",
                "Give short, basic descriptions of events and activities and can write very simple personal letters expressing thanks and apology.",
                "Write short, simple notes and messages relating to matters of everyday life.",
                "Describe plans and arrangements and can explain what they like or dislike about something.",
                "Describe their family, living conditions, schooling, present or most recent job and can describe past activities and personal experiences.",
              ],
            },
          ],
          footer: [
            {
              type: "p",
              class: "fw-bold text-blue2",
              text: "Exam preparation",
            },
            {
              type: "p",
              class: "",
              text: "The exam at the end of this level, for learners who finish their course of learning at A1 or have moved up and not completed the sufficient number of weeks at A2, is Test of Interactive English (TIE). Discrete support and extra classes are offered to those students to whom this applies. There is no specific exam content contained in this syllabus or scheme of work for this exam. However, the components of the exam rely on skills learned through the application of the established 25 week programme in the areas of reading, writing, speaking and listening. The NED C exam co-ordinator will be responsible for offering one-to-one or small-group support for those learners at A1 who are entered to take this exam.",
            },
          ],
        },
        {
          title: "A2 - B1 | Pre-Intermediate",
          tag: "TIE or Trinity ISE Foundation",
          body: [
            {
              type: "p",
              class: "fw-bold text-blue2",
              text: "Course Outline and objectives",
            },
            {
              type: "p",
              class: "",
              text: "General English Level A2 (Pre-intermediate) Course Outline and objectives The A2 General English course is for students who have begun to consolidate an understanding of elementary skills in English but do not yet display the range of competencies, across all four skill areas, which would lead them to be comfortable or confident at intermediate level. Students can interact in simple ways but will work towards expanding their range of grammatical structures, and vocabulary for everyday use in a range of contexts and self-promotion and confidence. Over the period of twenty five weeks, students will be expected to cover the language areas below:",
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "The following learning outcomes are assigned to the A2 (Pre-intermediate) course, by the end of which students will be able to:",
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "Receptive Skills",
            },
            {
              type: "ul",
              list: [
                "Use slightly more complex structures than at elementary level correctly, but still systematically make basic mistakes such as tense confusion and forget to mark agreement.",
                "Understand clear, slow, standard speech related to areas of most immediate personal relevance (e.g. very basic personal and family information, shopping, local geography and employment)",
                "Catch the main point in short, clear, simple messages and announcements.",
                "Understand short, simple texts containing high frequency vocabulary and shared international expressions.",
                "Find specific, predictable information in simple everyday material such as advertisements, prospectuses and timetables.",
                "Understand everyday signs and notices in public places, such as streets, restaurants, railway stations and in workplaces",
              ],
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "Productive Skills",
            },
            {
              type: "ul",
              list: [
                "Communicate in simple and routine tasks requiring a simple and direct exchange of information on familiar and routine matters.",
                "Describe in simple terms aspects of his/her background, immediate environment and matters in areas of immediate need.",
                "Handle short social exchanges being able to extend the conversation by themselves (through use of question tags/ follow-up questions)",
                "Have sufficient vocabulary for carrying out everyday tasks at the supermarket/ bank etc.",
                "Give extended descriptions of events and activities and can write very simple personal letters expressing thanks/ request and apology.",
                "Write short, simple notes and messages relating to matters of everyday life.",
                "Describe plans and arrangements and can explain what they like or dislike about something.",
                "Describe their family, living conditions, schooling, present or most recent job and can describe past activities and personal experiences.",
              ],
            },
          ],
          footer: [
            {
              type: "p",
              class: "fw-bold text-blue2",
              text: "Exam preparation",
            },
            {
              type: "p",
              class: "",
              text: "At A2 the designated exit examination is Trinity English. Built into the scheme of work for A2 are practice exercises of the type to be expected in the examination. The general English content of the course is deemed to be sufficient to prepare students for the language needed to pass the exam but extra practice is included in the scheme of work for teachers to familiarise students with the form of the test. Extra, discrete support will be offered by the NED C exam coordinator.",
            },
          ],
        },
        {
          title: "B1 - B2 | Intermediate",
          tag: "B1 Preliminary",
          body: [
            {
              type: "p",
              class: "fw-bold text-blue2",
              text: "Course Outline and objectives",
            },
            {
              type: "p",
              class: "",
              text: "The B1 course is aimed at learners who have begun to consolidate and implement their understanding of English grammatical structures and are seeking to develop a wider vocabulary with emphasis on pronunciation and in fluency in spoken and written English. Over the period of twenty five weeks, students will be expected to cover the language areas below:",
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "The following learning outcomes are assigned to the B1 (intermediate) course, by the end of which students will be able to:",
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "Receptive Skills",
            },
            {
              type: "ul",
              list: [
                "Understand straightforward texts on subjects related to their fields of interest",
                "Find and understand general information they need in everyday material, such as letters, brochures and short official documents",
                "Search one long or several short texts to locate specific information they need to help them complete a task",
                "Understand the main points of clear standard speech on familiar matters regularly encountered in work, school, leisure, etc.",
                "Understand the main point of many radio or TV programmes on current affairs or topics of personal or professional interest when the delivery is relatively slow and clear",
                "Guess the meaning of occasional unknown words from the context and understand sentence meaning",
                "Generally follow the main points of extended discussion",
                "Follow clear speech in everyday conversation, though in real life situations they will sometimes have to ask for repetition of particular words and phrases",
              ],
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "Productive Skills",
            },
            {
              type: "ul",
              list: [
                "Initiate, maintain and close simple face-to-face conversation on topics that are familiar or of personal interest",
                "Enter unprepared into conversation on topics that are familiar, of personal interest or pertinent to everyday life (e.g. family, hobbies, work, travel and current events)",
                "Use the most frequently-occurring connectors to link simple sentences to tell a story or describe something",
                "Briefly give reasons and explanations for opinions and plans",
                "Narrate a story or relate the plot of a book or film and describe reactions",
                "Write very brief reports, which pass on routine factual information and state reasons for actions write personal letters describing experiences, feelings and events in detail",
                "Describe basic details of unpredictable occurrences, e.g., an accident",
              ],
            },
          ],
          footer: [
            {
              type: "p",
              class: "fw-bold text-blue2",
              text: "Exam preparation",
            },
            {
              type: "p",
              class: "",
              text: "At B1 the designated exit examination is Cambridge B1 Preliminary. Built into the scheme of work for B1 are selections of practice tests in the skill areas examined by Cambridge. These appear every two weeks in the scheme of work. The course as a whole contains four complete practice papers divided into skill areas for closer analysis. Thereby, teachers may familiarise students with the nature of the reading, writing, listening and speaking papers that they will sit at the end of their course. Further practice papers and exercises will be offered to students as they approach the end of their course by the NED C exam coordinator.",
            },
          ],
        },
        {
          title: "B2 - C1 | Upper Intermediate",
          tag: "B2 First or IELTS",
          body: [
            {
              type: "p",
              class: "fw-bold text-blue2",
              text: "Course Outline and objectives",
            },
            {
              type: "p",
              class: "",
              text: "The B2 General English course is for students who display good communicative efficiency and strength in all four skill areas but who need to improve in fluency and implementation of more complex grammatical structures. They will seek to improve their comprehension of concrete and abstract topics and produce spoken and written English on a wide range of topics with minimal need for repair and allowance from native interlocutors. Over the period of twenty five weeks, students will be expected to cover the language areas below:",
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "The following learning outcomes are assigned to the B2 (upper-intermediate) course, by the end of which students will be able to:",
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "Receptive Skills",
            },
            {
              type: "ul",
              list: [
                "Read correspondence relating to their fields of interest and easily understand the essential meaning.",
                "Understand specialised articles outside their field, provided that they can use a dictionary to confirm terminology.",
                "Read many kinds of texts quite easily at different speeds and in different ways according to their purpose in reading and the type of text.",
                "Have a broad reading vocabulary, but sometimes experience difficulty with less common words and phrases.",
                "Quickly identify the content and relevance of news items, articles and reports on a wide range of professional topics, deciding whether closer study is worthwhile.",
                "Understand articles and reports concerned with contemporary problems in which the writers adopt particular stances or viewpoints.",
                "Understand standard speech spoken at a normal rate and follow even complex lines of argument provided the topic is reasonably familiar.",
                "Understand the essentials of lectures and most TV news and current affairs programmes and can understand the majority of films in standard dialect.",
                "Grasp concepts where only extreme background noise, unclear structure and/or idiomatic usage cause some problems.",
              ],
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "Productive Skills",
            },
            {
              type: "ul",
              list: [
                "Interact with a degree of fluency and spontaneity that makes regular interaction with native speakers quite possible.",
                "Take an active part in discussion in familiar contexts and can account for and sustain views clearly by providing relevant explanations and arguments.",
                "Present clear, detailed descriptions on a wide range of subjects related to their field of interest, expanding and supporting ideas with subsidiary points and relevant examples.",
                "Explain a viewpoint on a topical issue giving the advantages and disadvantages of various options.",
                "Write clear, detailed text on a wide range of subjects related to their interests. write an essay/ review/ article or report, passing on information or giving reasons in support of or against a particular point of view.",
                "Write letters/emails highlighting the personal significance of events and experiences.",
                "Synthesise information and arguments from a number of sources.",
                "Construct a chain of reasoned argument and can speculate about causes, consequences and hypothetical situations.",
              ],
            },
          ],
          footer: [
            {
              type: "p",
              class: "fw-bold text-blue2",
              text: "Exam preparation",
            },
            {
              type: "p",
              class: "",
              text: "At B2 the designated exit examination is Cambridge B2 First. The 25 week programme is structured to provide practice and analysis of Cambridge B2 First exam questions in context and within the framework of a general English course. There are eight full weeks of Cambridge-style exercises, activities and practice, interspersed at three-weekly intervals in the programme. Week 25 of the course is dedicated solely to the Cambridge exam with students working on example past papers. Through the course, students will become familiar with the type of questions, activities and language which will be tested. This forms around one third of the total content of a general course which focuses on communicative efficiency and effectiveness. Further practice papers and exercises will be offered to students as they approach the end of their course by the NED C exam coordinator.",
            },
          ],
        },
        {
          title: "C1 - C2 | Advanced",
          tag: "C1 Advanced or IELTS",
          body: [
            {
              type: "p",
              class: "fw-bold text-blue2",
              text: "Course Outline and objectives",
            },
            {
              type: "p",
              class: "",
              text: "The C1 General English course is for students who have a strong grasp of grammatical structures and can use English flexibly both written and spoken. Students will aim to expand their vocabulary and understanding of English as it is used in various educational, professional and social contexts to increase their communicative efficiency with a minimum of repair and searching for expressions.",
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "The following learning outcomes are assigned to the C1 (advanced) course, by the end of which students will be able to:",
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "Receptive Skills",
            },
            {
              type: "ul",
              list: [
                "Understand any correspondence with an occasional use of the dictionary.",
                "Understand in detail long, complex instructions on a new machine or procedure even outside their own field of specialization if they can reread difficult sections.",
                "Keep up with an animated conversation between native speakers and can understand enough to follow extended speech on abstract and complex topics beyond their own field.",
                "Recognise a wide range of idiomatic expressions and colloquialisms and recognise changes in style.",
                "Follow extended speech even when it is not clearly structured and when relationships between ideas are only implied and not stated explicitly.",
                "Follow most lectures, discussions and debates with relative ease and can extract specific information from poor quality public announcements.",
                "Understand complex technical information, such as operating instructions, specifications for familiar products and services.",
                "Understand a wide range of recorded audio material, including some nonstandard language, and identify finer points of detail, including implicit attitudes and relationships between speakers.",
                "Follow films which contain a considerable degree of slang and idiomatic usage",
              ],
            },
            {
              type: "p",
              class: "fw-bold text-green1",
              text: "Productive Skills",
            },
            {
              type: "ul",
              list: [
                "Present clear, detailed descriptions of complex subjects integrating sub-themes, developing particular points and rounding off with an appropriate conclusion.",
                "Communicate spontaneously, often showing remarkable fluency and ease of expression in even longer complex stretches of speech.",
                "Select an appropriate formulation from a broad range of language to express themselves clearly, without having to restrict what they want to say.",
                "Have a good command of a broad lexical repertoire allowing gaps to be readily overcome with circumlocutions; little obvious searching for expressions or avoidance strategies.",
                "Have a good command of idiomatic expressions and colloquialisms with occasional minor slips, but no significant vocabulary errors.",
                "Consistently maintain a high degree of grammatical accuracy; errors are rare and difficult to spot.",
                "Vary intonation and place sentence stress correctly in order to express finer shades of meaning.",
                "Use language flexibly and effectively for social purposes, including emotional, allusive and joking usage.",
                "Write clear, well-structured text and express points of view at some length.",
                "Write detailed expositions of complex subjects in a letter, an essay or a report, underlining the salient issues.",
                "Write different kinds of texts in a style appropriate to the reader in mind.",
              ],
            },
          ],
          footer: [
            {
              type: "p",
              class: "fw-bold text-blue2",
              text: "Exam preparation",
            },
            {
              type: "p",
              text: "At C1 the designated exit examination is C1 Advanced. The 25 week programme is structured to provide practice and analysis of Cambridge exam questions in context and within the framework of a general English course. There are eight full weeks of Cambridge-style exercises, activities and practice, interspersed at three-weekly intervals in the programme. Week 25 of the course is dedicated solely to the Cambridge exam with students working on example past papers.",
            },
            {
              type: "p",
              text: "Through the course, students will become familiar with the type of questions, activities and language which will be tested. This forms around one third of the total content of a general course which focuses on communicative efficiency and effectiveness. Further practice papers and exercises will be offered to students as they approach the end of their course by the NED C exam coordinator",
            },
          ],
        },
      ],
      button: "Talk to our agents!",
    },
    section3: {
      title: "End of course Exams",
      text: `Our teachers encourage and prepare the students to take exams at the end of their
            course. These exams have global recognition and will boost your professional profile.`,
    },
  },
  accreditations: {
    title: "High quality standards our students look for",
    breadcr: ["About Us", ">", "Associates & Accreditations"],
    section: {
      title1: `NED College is proudly associated with great institutions to guarantee the high quality
            standards our students look for. These accreditations contribute to consumer protection
            and make sure all our services meet the requirements demanded by law.`,
    },
    items: [ 
      {
        logo: "logo_eaquals",
        title: "EAQUALS - Excellence in Language Education",
        body: `NED College was inspected by Eaquals in 2023, an internationally recognized organization focused on ensuring excellence in language education, and met the high standards required for Eaquals accreditation. The teaching, the course programmes, as well as the course organisation, the learning resources, testing and evaluation were all judged to be of high quality. It was found that the institution takes great care to protect the welfare of its clients and staff, and all publicity materials produced by the institution are accurate and truthful. With EAQUALS accreditation, you can be confident in choosing NED College for a superior educational journey.`,
      },
      {
        logo: "logo_asic",
        title: "NED College holds International Accreditation from ASIC (Accreditation Service for International Schools, Colleges, and Universities) with Premier Status for its commendable Areas of Operation",
        body: `ASIC Accreditation is a leading, globally recognised quality standard in international education. Institutions undergo an impartial and independent external assessment process to confirm their provision meets rigorous internationally accepted standards, covering the whole spectrum of its administration, governance, and educational offering. Achieving ASIC Accreditation demonstrates to students and stakeholders that an institution is a high-quality education provider that delivers safe and rewarding educational experiences and is committed to continuous improvement throughout its operation.`,
      },
      {
        logo: "logo_ilep",
        title: "ILEP Interim List of Eligible Programmes",
        body: `This was implemented by the Department of Justice and Equality in 20th January 2016.
        Only schools that are on the list are able to receive Non-European students for long term
        courses. NED College is on the Interim list of schools.`,
      },
      {
        logo: "logo_progressive",
        title: "PCN Progressive College Network",
        body: `The Progressive College Network is an organisation formed to meet the demand for
          an alternate representative body for the private college community in Ireland.`,
      },
      {
        logo: "logo_academy_plus",
        title: "Academy Plus Insurance Brokers Learner Protection",
        body: `In line with current national regulations, all students enrolled on courses 3
        months or longer in duration, are obliged to purchase the Learner Protection as part of
        their course fees. This is an insurance that protects the student’s fees in the unlikely
        event of something happening to the school.`,
      },
      {
        logo: "logo_cambridge",
        title: "University of Cambridge ESOL Examinations",
        body: `The University of Cambridge offers the world’s most recognised range of English
          qualifications in the form of Cambridge ESOL examinations. Their extended network
          includes more than 2,700 centres and 20,000 examiners. NED College offers exam
          preparation for Cambridge exams and they can be taken at our local Cambridge exam
          centre in UL.`,
      },
      {
        logo: "logo_ielts",
        title: "IELTS",
        body: `The International English Language Testing System, is an international standardized test of English language proficiency for non-native English language speakers. It is jointly managed by the British Council, IDP: IELTS Australia and Cambridge Assessment English, and was established in 1989.`,
      },
      {
        logo: "logo_lap",
        title: "Academy Enrolled Learner Protection",
        body: `In line with current national regulations, all students enrolled on courses 3 months or longer in duration, are obliged to purchase the Learner Protection as part of their course fees. This is an insurance that protects the student’s fees in the unlikely event of something happening to the school.`,
      },
      {
        logo: "logo_tie",
        title: "TIE",
        body: `TIE is a modern, innovative and friendly ESOL qualification available internationally. awarded by Gatehouse Awards, TIE has been designed for candidates who are non-native speakers of the English language. Its aim is to enable candidates to develop their Speaking, Listening, Reading and Writing skills across all CEFR Levels (A1 to C2), assisting them to prepare for higher education or professional employment.`,
      },
    ],
  },
  campus_dublin: {
    title: "Dublin Campus",
    breadcr: ["Our campuses", ">", "Dublin"],
    section1: {
      title: `Dublin is Ireland's capital, a cosmopolitan and vibrant city that can be as cosy as an
            Irish pub.`,
      text: `Originally founded by Vikings, this city, surrounded by mountains and sea, has it all.
              With its impeccable combination of classic monuments, an excellent atmosphere and
              nightlife, countless recreational activities and green areas to wander in, Dublin has
              become one of the most popular destinations for international students to experience a
              great adventure abroad.`,
    },
    section2: {
      text: "Dublin has been the home of",
      text1: "NED College for more than 10 years.",
      text2:
        "During that time, we have grown and expanded to provide everything that a student needs to learn the most important language in the world.",
    },
    section3: {
      title: "Dublin Campus",
      text1: `NED College Dublin Campus has 2 buildings built in 1750 - all located in the heart of the city,
            accessed via various public transport services or on foot.`,
      text2: `At our Dublin campus, we are committed to providing a comfortable and supportive
            environment for our multicultural students and enhancing the student experience, making
            it an ideal place to study and grow.`,
    },
    section4: {
      cards: [
        {
          icon: "girl",
          title: "Reception",
          body: "Reception area, where our friendly receptionists assist students with their questions.",
        },
        {
          icon: "cuttlery",
          title: "Canteen",
          body: "Beautiful canteen that offers to our students a variety of hot drinks, soft drinks, and food options.",
        },
        {
          icon: "classroom",
          title: "Classrooms",
          body: "Another key feature of our campus is the modern, fully-equipped classrooms.",
        },
        {
          icon: "building",
          title: "2 buildings",
          body: "2-building infrastructure to support and enhance the student experience, making it an ideal place to study and grow.",
        },
        {
          icon: "sofa",
          title: "Lounge area",
          body: "We also have a lounge area with a library, where students can come together to relax, chat, study or make new friends.",
        },
      ],
    },
    section5: {
      title: "TAKE A LOOK AT OUR BUILDINGS",
      button: "I want to study here",
    },
    section6: {
      title: "What You Can Find Near NED College Dublin Campus",
      text1: `Stops for the main`,
      text2: "public transport",
      text3: "in the city",
      text4: `Main`,
      text5: "tourist attractions and monuments",
      text6: `Shopping centres, shops and`,
      text7: "different types of restaurants",
    },
    testimonialsection: {
      title: "Check out what our students are saying about NED College",
    },
    mapsection: {
      title: "A mix of nationalities",
      body: `Every month at NED College, we welcome students from all over the world. To give you a better and personalized service,`,
      body1:
        "we offer student support in your own language before arrival and during your study period in Ireland.",
    },
    section7: {
      title1: "Get to know our Dublin Campus buildings",
      title2: "What are you waiting for?",
      button: "Choose Dublin campus",
    },
  },
  campus_limerick: {
    title: "Limerick Campus",
    breadcr: ["Our campuses", ">", "Limerick"],
    section1: {
      title: `Originally founded by the Vikings, Limerick is a traditional city, where authentic Irish
            culture can be experienced.`,
      text: `Limerick is located in the Midwest of Ireland and it has 191,000 inhabitants, making it
            the third largest city in the country. There is always something to do in Limerick City,
            as there are many fascinating historic sights, old pubs, shopping areas, cultural
            events, delectable eateries and excellent public transport. That’s why Limerick is the
            perfect place to learn English while enjoying the quality of life and low cost that the
            city offers, compared to the rest of the country.`,
    },
    section2: {
      text: "In 2019 we opened our brand new school in this city",
      text1:
        " and which has been a complete success! Since then, we have received hundreds of students from different countries and more are arriving every month!",
    },
    section3: {
      title: "Limerick Campus",
      text: `NED College's Limerick Campus is located in the heart of the city,
            offering completely new and modern facilities to students. As in Dublin at our Limerick campus, we
            are committed to providing a supportive and comfortable environment for our
            multicultural students.`,
    },
    section4: {
      cards: [
        {
          icon: "girl",
          title: "Reception",
          body: "Reception area, where our friendly receptionists assist students with their questions.",
        },
        {
          icon: "cuttlery",
          title: "Canteen",
          body: "Beautiful canteen that offers to our students a variety of hot drinks, soft drinks, and food options.",
        },
        {
          icon: "classroom",
          title: "Classrooms",
          body: "Another key feature of our campus is the modern, fully-equipped classrooms.",
        },
        {
          icon: "building",
          title: "1 building",
          body: "1-building infrastructure to support and enhance the student experience, making it an ideal place to study and grow.",
        },
        {
          icon: "sofa",
          title: "Lounge area",
          body: "We also have a lounge area with a library, where students can come together to relax, chat, study or make new friends.",
        },
        {
          icon: "disabled",
          title: "Disabled bathroom",
          body: "Clean and modern disabled toilets.",
        },
      ],
    },
    section5: {
      title: "Take a look at our Limerick Campus",
      button: "I want to study here",
    },
    section6: {
      title: "What is near NED College's Limerick Campus",
      text1: `Bus stops for the main`,
      text2: "public transport",
      text3: "in the city",
      text4: `Many`,
      text5: "tourist attractions and monuments",
      text6: `Shopping centres, shops,`,
      text7: `many different types of restaurants`,
      text8: `and cultural settings`,
    },
    testimonialsection: {
      title: "Check out what our students are saying about NED College",
    },
    mapsection: {
      title: "A mix of nationalities",
      body: `Every month at NED College, we welcome students from all over the world. To give you a better and personalized service,`,
      body1:
        "we offer student support in your own language before arrival and during your study period in Ireland.",
    },
    section7: {
      title1: "Get to know our Limerick Campus",
      title2: "What are you waiting for?",
      button: "Choose Limerick campus",
    },
  },
  contact_us: {
    title: "Get in touch",
    breadcr: ["Contact us"],
    section1: {
      title: ` Come on! Take the first step to study English in Ireland! We are here to answer all your
            questions.`,
    },
    section3: {
      title1: "Get to know our locations",
      title2: "Dublin Campus",
    },
    section4: {
      title: "Limerick Campus",
    },
  },
  faqs: {
    title: "Frequently Asked Questions",
    breadcr: ["FAQs"],
    section1: {
      title: "We know that preparing yourself to travel abroad is not easy.",
      text: `That is why we have prepared this special FAQs to help you better understand everything
            you need to know about studying English and working in Ireland.`,
    },
    sections: [
      {
        title: "Before your travel to Ireland",
        items: [
          {
            title: "Why study abroad?",
            body: [
              {
                type: "p",
                class: "",
                text: `To study abroad is a dream pursued by many young people and more recently by people of all
                ages. The decision to study abroad must be made alone. Ask yourself what motivation you have
                to pursue this dream. Although your motivations are personal and it is totally up to you to
                make up your mind about it, studies have proven that people who have studied abroad have
                improved not only their language skills but also increased their confidence, self-esteem and
                capacity to solve problems and deal with pressure. To learn more about this, have a look at
                this study:`,
              },
              {
                type: "a",
                class: "",
                link: "https://www.iesabroad.org/study-abroad/benefits",
                text: "https://www.iesabroad.org/study-abroad/benefits",
              },
            ],
          },
          {
            title: `Why Study in Ireland?`,
            body: [
              {
                type: "p",
                class: "",
                text: `Ireland has increasingly becoming popular among students from all over the globe as its immigration laws are considered flexible and open to non-European students. Students can come for short or long-term studies, In the latter case it’s possible to work whilst studying in the country. It is a fair easy process to apply for the visa, as it happens after the student’s arrival in the country. Apart from the motives above, Ireland is a beautiful and very historic country, offering several types of cultural activities and the opportunity to get to know people from everywhere. It goes without saying that Ireland is very well located and offers the chepeast flights to other countries in Europe.`,
              },
            ],
          },
          {
            title: `Where to Study English in Ireland?`,
            body: [
              {
                type: "p",
                class: "",
                text: `There are many cities in Ireland which offer both opportunities to learn English and to work. The favourites are Dublin, Cork, Galway and Limerick. At NED College, you can choose between Dublin and Limerick`,
              },
            ],
          },
          {
            title: `What is a Non-European Citizen?`,
            body: [
              {
                type: "p",
                class: "",
                text: `A non-EU national is an individual who originates from countries that are not part of the EU agreement. EU nationals have the freedom to move to another EU country to work without a work permit The only exceptions to this are people coming from Iceland, Liechtenstein and Norway, as their citizens can work in the EU on the same footing as EU nationals, since they belong to the European Economic Area. To check the exact rights regarding your home country, access:`,
              },
              {
                type: "a",
                class: "",
                link: "http://ec.europa.eu/social/main.jsp?catId=470&langId=en",
                text: "http://ec.europa.eu/social/main.jsp?catId=470&langId=en",
              },
            ],
          },
          {
            title: `Can I work and study in Ireland as a NON-European Citizen?`,
            body: [
              {
                type: "p",
                class: "",
                text: `Yes. This is part of what makes Ireland such an attractive destination for your study abroad programme. By enrolling on a long-term course, you are allowed to work 20 hours a week during regular times and 40 hours during the Summer and Christmas holidays (The specific dates are defined by the Irish Immigrations authorities). Stamp 2 visa holders are permitted to work full time in accordance with employment law provisions during the months of June, July, August and September and from 15th December to 15th January inclusive. At all other times they will be limited to working 20 hours per week. Learn more: `,
              },
              {
                type: "a",
                class: "",
                link: "http://www.inis.gov.ie/en/INIS/Pages/Frequently%20Asked%20Questions%20-%20Students",
                text: "http://www.inis.gov.ie/en/INIS/Pages/Frequently%20Asked%20Questions%20-%20Students",
              },
            ],
          },
          {
            title: `Can I work after study in Ireland?`,
            body: [
              {
                type: "p",
                class: "",
                text: `If you do not have a European passport, you can only work while studying in Ireland. However, upon
                completion of a High Education Level (Graduate, Post graduate, Masters or PhD), you might avail of a 1 to 2 year
                work permit.`,
              },
            ],
          },
          {
            title: `What should I study in Ireland?`,
            body: [
              {
                type: "p",
                text: `You can study whatever course you are interested in as long as you got accepted by an Irish Institution.
                Note that to be able to work part time in Ireland, you will have to be enrolled in a full time course. These include:
                English Language, Higher National Diploma, Master’s degree and PhD. We reccomend you start off your studies with an
                English language Course, preferably at NED College, as we are committed to offer you the best support,
                so that you will increase your chances of being accepted by an higher level course later on.`,
              },
            ],
          },
          {
            title: `What is the minimum age to do a student exchange programme?`,
            body: [
              {
                type: "p",
                text: `At NED College we are happy to welcome students who are 18 years old or older. If you are under 18
                years old, we can accept you only for a short-term course, from 1 to 12 weeks duration. Short stay ‘C’
                visas for children aged under 18 must show if the child is travelling alone (unaccompanied) or with an adult
                (accompanied). If the child is accompanied, the person(s) named on the child’s visa must be with them for the
                 entire journey. If not, the child will not be allowed to travel. To learn:`,
              },
              {
                type: "a",
                link: "http://www.inis.gov.ie/en/INIS/Pages/visa-short-stay-children",
                text: "http://www.inis.gov.ie/en/INIS/Pages/visa-short-stay-children",
              },
            ],
          },
          {
            title: `What is a Stamp 2?`,
            body: [
              {
                type: "p",
                text: `It is permission to study in Ireland. Stamp 2 indicates permission to study a full time course on
                the official `,
              },
              {
                type: "a",
                link: "https://www.irishimmigration.ie/coming-to-study-in-ireland/what-are-my-study-options/a-third-level-course-or-a-language-course/",
                text: "Interim List of Eligible Programmes (ILEP)",
              },
              {
                type: "p",
                text: `for a specified period, subject to conditions. According to Irish regulation, the student needs to be enrolled on one
                of the following courses: English Language, Higher National Diploma, Degree (undergraduate), Master’s degree
                (postgraduate), PhD. To learn more: `,
              },
              {
                type: "a",
                link: "http://www.inis.gov.ie/en/INIS/Pages/registration-stamps#stamp2",
                text: "http://www.inis.gov.ie/en/INIS/Pages/registration-stamps#stamp2",
              },
            ],
          },
          {
            title: `Do I have to apply for a visa to study in Ireland?`,
            body: [
              {
                type: "p",
                text: `t will depend on the length you intend to study in Ireland and also the country you come from.
                To check if your country’s citizens requires a visa to enter Ireland, click on this link: `,
              },
              {
                type: "p",
                link: "http://www.inis.gov.ie/en/INIS/Pages/check-irish-visa",
                text: `http://www.inis.gov.ie/en/INIS/Pages/check-irish-visa`,
              },
              {
                type: "p",
                text: `Generally, to study in Ireland for less than 3 months, you will not have to apply for a visa. However, if you intend to work while studying in Ireland, you will need to apply for a visa, once you arrive in the country. This rule applies only to Non-European citizens. `,
              },
            ],
          },
          {
            title: `Do I have to book my English course before my trip starts?`,
            body: [
              {
                type: "p",
                text: `Yes, you do. Note that the school letter stating that you are enrolled in an English course is one of the
                most important documents you should present when crossing the border into Ireland. This requirement applies for
                 both short and long-term courses. Showing that you are entering the country for educational reasons will be fundamental
                 to your success.`,
              },
            ],
          },
          {
            title: `Which documents should I present at the airport at the Irish border control?`,
            body: [
              {
                type: "p",
                text: `When arriving in Ireland you will have to present your passport
                (valid for at least 6 months and/or the period you intend to remain in the country, which means that if you
                booked a course of 8 months, your passport needs to be valid for the next 8 months),
                the school letter, the accommodation letter, proof of funds (card statment or cash), and the printed return flight tickets.`,
              },
            ],
          },
          {
            title: `What is the English level required to study abroad?`,
            body: [
              {
                type: "p",
                text: `If you are coming to learn English, we do not require any knowledge of the language, as we offer
              English courses starting from the most elementary level. However, if you intend to study Higher Level Courses,
              you will be required to prove a higher possibly even advanced level of English.`,
              },
            ],
          },
          {
            title: `How long in advance should I book my course?`,
            body: [
              {
                type: "p",
                text: `It depends on our availability. You might need more time to save enough money to come to Ireland.
                However, if you have already made up your mind and decide to come immediately, we will do our best to
                guarantee you a place at our school. Furthermore, we recommend you book your course three months in advance to
                guarantee a place, better flight promotions and etc. Once you booked and have fully paid your fees,
                you will need to travel to Ireland within the next year. If this period expires, we will no longer
                accommodate you and you will not be entitled to receive any refund.`,
              },
            ],
          },
          {
            title: `Can I work in Ireland while taking a short-term English Course?`,
            body: [
              {
                type: "p",
                text: `No, you cannot. You can study in Ireland by taking a short or a long-term course. The difference is that
                in a short-term course you don’t get permission to work. However, you can dive into the Irish culture while l
                iving in the country. This option is recommended to those who do not have much time to travel abroad and are
                willing to use their work holidays to travel and study`,
              },
            ],
          },
          {
            title: `What type of courses enable me to work in Ireland?`,
            body: [
              {
                type: "p",
                text: `In order to get a Stamp 2, you need to be enrolled on a long-term course with a minimum length of
                8 months (work and study program 6-month English Course + 2-month holiday).`,
              },
            ],
          },
          {
            title: `How many hours can I work in Ireland?`,
            body: [
              {
                type: "p",
                text: `Once enrolled in a full time English Course, you are allowed to work in Ireland for 20 hours a week in
                regular months and up to 40 hours during the months of summer and Christmas holiday Stamp 2 visa holders are
                permitted to work full time in accordance with employment law provisions during the months of June, July, August
                and September and from 15th December to 15th January inclusive. At all other times they will be limited to
                working 20 hours per week.`,
              },
            ],
          },
          {
            title: `Do I need Health Insurance?`,
            body: [
              {
                type: "p",
                text: `Yes. If you are a Non-European citizen, you will be required to have private Medical Insurance.
                With this, you will be safeguarded in case of medical emergencies while living abroad. Insurance is mandatory
                for long-term programmes. When booking your course with NED College, your Health Insurance will be included in
                the package, so you will not have to worry about it.`,
              },
            ],
          },
          {
            title: `Which is the best time of the year to travel?`,
            body: [
              {
                type: "p",
                text: `The best time is the one that fits your own necessities. It will depend on each student’s lifestyle.
                However, we recommend the summer time for several reasons: some students are heading back home, which makes it
                the best time for finding accommodation; jobs and making new friends as a new bunch of people are coming in.
                For those students who prefer Winter it would be better to arrive in the months between October to April,
                for those who prefer hot weather they can come from April to September. If they like partying they can come
                for Saint Patricks in March or Halloween in October. In overall, it will depend on your taste and aims.`,
              },
            ],
          },
          {
            title: `Does NED College sell flight tickets?`,
            body: [
              {
                type: "p",
                text: `No. We offer support to help you find the best options and prices. As a student, you also can avail
                of student fees and conditions. However, it is the full responsibility of students to book their flight
                tickets. However, we might have a partnership with some travel companies, so you must ask to your agent.`,
              },
            ],
          },
          {
            title: `Do I have to buy a return flight ticket?`,
            body: [
              {
                type: "p",
                text: `Yes. It is a legal requirement to present the return flight ticket when arriving at Irish border
                control. Not having the return flight can result in deportation.`,
              },
            ],
          },
          {
            title: `Do I have to get special vaccines before my trip?`,
            body: [
              {
                type: "p",
                text: `No. There is no legal requirement. However, accordingly to the CDC (Centres for Disease Control and
                  Prevention), all travellers should make sure they are up-to-date on routine vaccines before any trip.
                  The recommend vaccine to have before your trip is Hepatitis A. To learn more:`,
              },
              {
                type: "a",
                link: "https://wwwnc.cdc.gov/travel/destinations/traveler/none/ireland",
                text: "https://wwwnc.cdc.gov/travel/destinations/traveler/none/ireland",
              },
            ],
          },
          {
            title: `How much money should I bring to Ireland?`,
            body: [
              {
                type: "p",
                text: `If the duration of your course is less than 6 months you must have access to €500 per month of your
                stay or €3000 whichever is the lesser. €4500, if you are staying for more than 6 months.`,
              },
            ],
          },
          {
            title: `How do I make an appointment at Irish Immigration?`,
            body: [
              {
                type: "p",
                text: "After your arrival in Ireland, your first concern will be to apply for your long-stay visa. You will collect all the necessary documents at our school, these are: School letter, Health insurance certificate, plus your Financial Proof Statement.",
              },
              {
                type: "p",
                text: "If you have chosen Dublin, when you have all the documents, trocar por: you need to call Freephone 1800 800 630 to book your appointment. If you need help, ask for your consultant. Please check immigration website for further information: ",
              },
              {
                type: "a",
                link: "https://www.irishimmigration.ie/burgh-quay-appointments",
                text: "https://www.irishimmigration.ie/burgh-quay-appointments",
              },
              {
                type: "p",
                text: `If you have chosen Limerick, please send an email to limerick.registration@garda.ie to book and appointment.
                *Remember that you can only book when you are in Ireland.`,
              },
            ],
          },
          {
            title: `How can I prove I have sufficient funds to support myself during my stay?`,
            body: [
              {
                type: "p",
                class: "",
                text: "You can prove you have enough funds by presenting one of the options below:",
              },
              {
                type: "ul",
                list: [
                  {
                    title: "Proof from an Irish bank",
                    text: "If you choose this option, you must bring an original statement from an Irish bank that shows a balance of €4,500",
                  },
                  {
                    title: "Proof from a foreign bank",
                    text: "If you choose this option, you must bring 2 original bank statements from a foreign bank",
                  },
                  {
                    title: "Proof from a financial services company in Ireland",
                    text: "If you choose this option, you must bring an original letter from a financial services company in Ireland that shows it holds €4,500 ‘in trust’ for you",
                  },
                  {
                    title: "Proof via a pre-paid credit card or debit card",
                    text: "If you choose this option, you must bring evidence to demonstrate to the registration office that you have a pre-paid credit card(s) or debit card(s) with €3,000",
                  },
                ],
              },
              {
                type: "p",
                text: "For more information, consult the Immigration website: ",
              },
              {
                type: "a",
                link: "https://www.irishimmigration.ie/registering-your-immigration-permission/how-to-register-your-immigration-permission-for-the-first-time/",
                text: "https://www.irishimmigration.ie/registering-your-immigration-permission/how-to-register-your-immigration-permission-for-the-first-time/",
              },
            ],
          },
          {
            title: `What is an IRP or GNIB card?`,
            body: [
              {
                type: "p",
                text: `Irish Residence Permit (IRP) is a registration certificate that proofs you are allowed to remain and work
                in Ireland during your stay. An IRP costs €300 per person each time you apply for a student visa
                in the country. You can remain in Ireland for up to 2 years as an English Student. This means that every time you
                renew your course, you will have to pay the fee of €300 for a new IRP card. GNIB is what the IRP card used to be
                called. Take note that this card is very important because, although it does not serve as an official identification
                method, it is necessary for immigration purposes every time you leave or enter the country, so we recommend that you
                keep it in your residence and do not carry it on the street.`,
              },
            ],
          },
          {
            title: `How do I pay for my IRP card?`,
            body: [
              {
                type: "p",
                text: `Pay by credit card or debit card at Burgh Quay registration office and select offices around the country.
                To pay using credit card or debit card, bring your card with you and use as normal.`,
              },
            ],
          },
        ],
      },
      {
        title: "About our School",
        items: [
          {
            title: "Which English Courses does NED College offer?",
            body: [
              {
                type: "p",
                text: "We offer General English Courses and IELTS, learn more here:",
              },
              {
                type: "a",
                link: "https://ned.ie/courses/",
                text: "https://ned.ie/courses/",
              },
            ],
          },
          {
            title: `Why should I choose NED College?`,
            body: [
              {
                type: "p",
                text: `Our modern school boasts over 20 fully equipped classrooms on both Dublin and Limerick campuses,
                designed to offer students an optimal learning environment where they can thrive and reach their full potential.
                Additionally, we have a welcoming reception area, a spacious canteen, and a comfortable student lounge.`,
              },
            ],
          },
          {
            title: `What exam preparation does NED College offer?`,
            body: [
              {
                type: "p",
                text: `We prepare our students for different types of English tests: TIE, Trinity Integrated Skills in English,
                ISE, IELTS and Cambridge. The end of course examination is mandatory for all visa-required students following GNIB
                rules and the fees are included in most of our packages. We recommend that you check with your agent to make sure it
                is also included in yours.`,
              },
            ],
          },
          {
            title: `What is the TIE exam?`,
            body: [
              {
                type: "p",
                text: `The Test of Interactive English (TIE) is an international EFL exam developed in Ireland by a
                group of Irish English language professionals under the aegis of the Advisory Council for English Language
                Schools (ACELS), a government agency which was responsible at that time for quality assurance in English
                language teaching. TIE can be taken by junior and adult students on either short or long courses in Ireland
                as well as abroad. To learn more: `,
              },
              {
                type: "a",
                link: "https://www.yesireland.ie/i-have-a-tie-exam-and-now/",
                text: "https://www.yesireland.ie/i-have-a-tie-exam-and-now/",
              },
            ],
          },
          {
            title: `What is a Cambridge Exam?`,
            body: [
              {
                type: "p",
                text: `If you have achieved a higher English Level this means that now you are more than prepared
                to take more advanced English tests. For that, we offer our students the opportunity to test their
                skills by taking three different types of Cambridge exams: B1 Preliminary (PET), B2 First (FCE) and C1 Advanced (CAE). To learn more about each
                one of them, click here:`,
              },
              {
                type: "a",
                link: "https://www.yesireland.ie/cambridge-exams-pet-fce-and-cae-what-are-they/",
                text: "https://www.yesireland.ie/cambridge-exams-pet-fce-and-cae-what-are-they/",
              },
            ],
          },
          {
            title: `Do I have to attend 100% of the classes?`,
            body: [
              {
                type: "p",
                text: `Ideally you should, nevertheless many unexpected events might happen, so you are unlikely to
                attend 100% of the classes. The minimum attendance rate is 85%. Because it is a legal requirement,
                you need to bear in mind that not keeping your attendance at 85% might lead to a refusal in case you
                try to renew your visa at the Irish Immigration Office.`,
              },
            ],
          },
          {
            title: `Can I be expelled from NED College?`,
            body: [
              {
                type: "p",
                text: `Yes. If a student is unable to complete his/her course with 85% attendance, NED College retains
                the right to expel the student without issuing four warnings. Attendance is a requirement of a student’s
                visa and non-attendance will result in expulsion and may result in the loss of a student’s permission to
                study and work in Ireland. In our community, we do not tolerate any form of misconduct and failure to a
                dhere to this may lead to expulsion. To learn more read our School Policy here: `,
              },
              {
                type: "a",
                link: "https://ned.ie/en/about_us/privacy_policy",
                text: "https://ned.ie/en/about_us/privacy_policy",
              },
            ],
          },
          {
            title: `Expulsion Appeals Process`,
            body: [
              {
                type: "p",
                text: `At any stage during the process outlined above, students are welcome and encouraged to discuss any issue(s) which may be contributing to them not being able to meet attendance requirements. The school has an open-door policy, numerous personnel available, as well as various methods of communication options open to students. If during the process outlined above, a student;`,
              },
              {
                type: "p",
                text: "(A) Does not reply/interact/engage with the school;",
              },
              {
                type: "p",
                text: "(B) Continues to not attend class;",
              },
              {
                type: "p",
                text: "(C) If a student is unable to reach 85% attendance before the end of his/her course - NED College retains the right to expel them without issuing all warnings.",
              },
            ],
          },
          {
            title: `How do I know my English Level?`,
            body: [
              {
                type: "p",
                text: `Once you book your course, you will receive a link to your placement test by email,
                in which you will be assessed on your grammar, reading and writing. However, your final level and
                class will be defined once you step into our school. Depending on the situation and the NED College
                campus you have chosen, you will have a virtual or face-to-face oral test with us and you will be
                placed in the right class and level.`,
              },
            ],
          },
          {
            title: `At what time are the classes and the school opening times?`,
            body: [
              {
                type: "p",
                text: `We offer two class times, morning from 09:00 to 12:15, and afternoon from 13:00 to 16:15,
                from Monday to Friday. Our school is open from 9:00 to 16:30.`,
              },
            ],
          },
          {
            title: `Does NED College offer any extracurricular activities?`,
            body: [
              {
                type: "p",
                text: `Yes. At NED College, we believe that the learning process goes beyond in-class activities.
                We offer extracurricular activities. There are: grammar, conversation, pronunciation and CV and interview
                workshop classes for free. We believe our students should be motivated to progress in all aspects of the
                language. Furthermore, we offer other types of free workshops, such as dance, tai chi, acting, to provide
                an environment of multicultural interaction and entertainment.`,
              },
            ],
          },
          {
            title: `What’s it like studying at NED College?`,
            body: [
              {
                type: "p",
                text: `At NED College, we work hard on providing your students with the best possible experience.
                That is why our approach is to offer excellence in teaching, by hiring excellent teachers as well as
                offering complementary activities, weekly assessments and monthly surveys to guarantee we are delivering
                the best services to our students. Although at NED College, you will be pushed to do your best and achieve
                great results, you will have plenty of fun and can fill your free time with amusing activities to ensure
                an unforgettable experience.`,
              },
            ],
          },
          {
            title: `What are the English levels at NED College?`,
            body: [
              {
                type: "p",
                text: `There are 6 English Levels at NED College. They are:
                CEFR level (1.1 Beginner PreA1, 1.2 Elementary A1, 2. Pre-Intermediate A2, 3. Intermediate B1, 4. Upper Intermediate B2, 5 Advanced C1)
                In every level, our teachers will work on improving your skills in Reading, Speaking, Writing and
                Listening.`,
              },
            ],
          },
          {
            title: `Do I receive a certificate at the end of my course?`,
            body: [
              {
                type: "p",
                text: `Yes. All students who succeed in completing their courses with a minimum of 85% attendance will
                receive a certificate stating their current language level. Student must request from the NED College app
                when their course has finished. Students will only get a certificate after completing 6 weeks of the
                course at their final level (it does not apply to short-term students, as some of the course can be shorter
                than 6 weeks).`,
              },
            ],
          },
          {
            title: `Is there any support in my native language?`,
            body: [
              {
                type: "p",
                text: `Yes. NED College English School is fully prepared to attend to students from all over the world.
                We have specialised support in seven languages (English, Chinese, Malay, Korean, Portuguese, Spanish, Japanese
                  and Turkish). Our website is available in English, Portuguese and Spanish. Our school is committed to offer
                  support in all stages of your course from planning in your home country to during your course and post-course;
                  analysing your feedback on your experience to improve the quality of our services.`,
              },
            ],
          },
          {
            title: `What services can I book with NED College?`,
            body: [
              {
                type: "p",
                text: `You can book almost everything you need for your exchange experience with us, apart from
                the flight tickets. We offer: Long-term English Course, short-term English Course, temporary
                accommodation, airport pick-Up, enrolled learner protection, health insurance, student card,
                exams and certification, and all the support in your native language to avoid any miscommunication.
                Learn more about each of these services: `,
              },
              {
                type: "a",
                link: "https://ned.ie/student_life/services/",
                text: "https://ned.ie/student_life/services/",
              },
            ],
          },
          {
            title: `What kind of accommodation does the school provide?`,
            body: [
              {
                type: "p",
                text: `We offer temporary accommodation. Our aim is to make you feel at home in Ireland.
                Your accommodation is an extremely important part of your whole experience. Our accommodation options
                are all located close to public transport, shopping centres, supermarkets and banks. We work with host
                families, hostels and student accommodation. Learn more:`,
              },
              {
                type: "a",
                link: "https://ned.ie/student_life/services/",
                text: "https://ned.ie/student_life/services/",
              },
            ],
          },
          {
            title: `What are NED College affiliations and partnerships?`,
            body: [
              {
                type: "p",
                text: `NED College is proudly associated with great institutions to guarantee the high-quality standards our students look for. These accreditations contribute to consumer protection and make sure all our services meet the requirements demanded by law`,
              },
              {
                type: "p",
                text: `NED College is ILEP (Interim List of Eligible Programmes) approved. This is currently the national accreditation system in Ireland. Please find the full ILEP list here:`,
              },
              {
                type: "a",
                link: "https://www.irishimmigration.ie/coming-to-study-in-ireland/what-are-my-study-options/a-third-level-course-or-a-language-course/",
                text: "https://www.irishimmigration.ie/coming-to-study-in-ireland/what-are-my-study-options/a-third-level-course-or-a-language-course/",
              },
              {
                type: "p",
                text: `NED College is an authorised Centre offering Examinations Preparation Courses for University of Cambridge – ESOL Examinations. This means that while studying at NED College, you will be prepared to take the world’s most recognised range of English qualifications, enhancing your career prospects.`,
              },
              {
                type: "p",
                text: "NED College is part of the Progressive College Network, which means that our is school is constantly being examined in relation to its quality and infrastructure. The PCN is an organization formed to meet the demand for an alternate representative body for the private college community in Ireland.",
              },
              {
                type: "p",
                text: `NED College is approved to offer Learner Protection Insurance. After booking your course, you will receive a certificate to confirm that you are safeguarded in case anything happens to our school. Learn more about our accreditations at:`,
              },
              {
                type: "a",
                link: "https://ned.ie/about_us/accreditations/",
                text: "https://ned.ie/about_us/accreditations/",
              },
            ],
          },
          {
            title: `What is NOT tolerated at NED College?`,
            body: [
              {
                type: "p",
                text: `Racism, homophobic comments, bigotry and related issues will not be tolerated at any time.
                Aggression towards fellow students or staff members is strictly forbidden; any student who behaves
                 in an aggressive manner will be asked to leave class and may face suspension or expulsion.`,
              },
            ],
          },
          {
            title: `What Communication Channels is NED College on?`,
            body: [
              {
                type: "p",
                text: `Website:`,
              },
              {
                type: "a",
                link: "https://ned.ie",
                text: "https://ned.ie",
              },
              {
                type: "p",
                text: `Instagram:`,
              },
              {
                type: "a",
                link: "https://www.instagram.com/nedcollege/",
                text: "https://www.instagram.com/nedcollege/",
              },
              {
                type: "p",
                text: `Facebook:`,
              },
              {
                type: "a",
                link: "https://www.facebook.com/NEDCollege/",
                text: "https://www.facebook.com/NEDCollege/",
              },
              {
                type: "p",
                text: `LinkedIn:`,
              },
              {
                type: "a",
                link: "https://www.linkedin.com/company/nedcollege",
                text: "https://www.linkedin.com/company/nedcollege",
              },
              {
                type: "p",
                text: `YouTube:`,
              },
              {
                type: "a",
                link: "https://www.youtube.com/nedtcschool",
                text: "https://www.youtube.com/nedtcschool",
              },
            ],
          },
        ],
      },
      {
        title: "Living in Ireland",
        items: [
          {
            title: "Where to find permanent accommodation?",
            body: [
              {
                type: "p",
                text: `Because Ireland is a fairly small country, finding accommodation should not be difficult as
                the country is prepared to receive students from everywhere. Dublin city is divided into 24 different
                postal districts: the odd numbers are situated on the north side of the river Liffey, while even
                numbers are located on the south side. Limerick is the third-largest city in the Republic of Ireland,
                is located in the mid-west of Ireland, on the River Shannon. There are a variety of neighbourhoods in
                Limerick where you can find accommodation, ranging from student areas to more residential neighbourhoods.`,
              },
            ],
          },
          {
            title: `How much does it cost to live in Ireland?`,
            body: [
              {
                type: "p",
                text: `Ireland is one of the most important economies in Europe nowadays. This means that even though
                you might have to invest a little bit more money to come to study here, the money earned will be worth
                it more than your current currency.`,
              },
            ],
          },
          {
            title: `What documents do I need to work in Ireland?`,
            body: [
              {
                type: "p",
                text: `Once you have applied and received your IRP (Irish Residence Card). to be able to work,
                you will need to apply for your PPS Number. A PPS number stands for: Personal Public Service (PPS) number. It is a unique reference number that helps you access social welfare benefits,
                public services and information in Ireland. To apply for a PPS number, you need to get an appointment. Access:`,
              },
              {
                type: "a",
                link: "http://www.citizensinformation.ie/en/social_welfare/irish_social_welfare_system/personal_public_service_number.html",
                text: "http://www.citizensinformation.ie/en/social_welfare/irish_social_welfare_system/personal_public_service_number.html",
              },
            ],
          },
          {
            title: `How do I register a Job with the authorities?`,
            body: [
              {
                type: "p",
                text: `After getting your first job, you will need to manage your taxes. This is very important while
                living in Ireland. You need to sign up on the Revenue website: `,
              },
              {
                type: "a",
                link: "https://www.revenue.ie/en/Home.aspx",
                text: "https://www.revenue.ie/en/Home.aspx",
              },
            ],
          },
          {
            title: `Is it easy to find a job in Ireland if my level of English is still beginner?`,
            body: [
              {
                type: "p",
                text: `The Irish Economy has been growing massively over the last 10 years. This has contributed to
                increases the amount of jobs available. In hospitality, there are many part-time jobs available that
                many students can take on. Due to the high demand, we can confirm that it is fairly easy to find a
                job in Ireland, even if your English level is still beginner. Furthermore, working is another great
                way to improve your language skills. We offer a weekly free Interview workshop and CV preparation to
                help you to get a job as fast as possible.`,
              },
            ],
          },
          {
            title: `What kind of job do students usually do in Ireland?`,
            body: [
              {
                type: "p",
                text: `Because many students can only work part-time, the types of jobs they are more likely to take
                on are in the retail, hospitality and caring sectors. This means that there are many options such as:
                 deli assistant, barista, sales assistant, bar tender, au pair, child minder, cleaner, floor staff,
                 waitress/ waiter, kitchen porter, etc.`,
              },
            ],
          },
          {
            title: `How much will I get paid working in Ireland?`,
            body: [
              {
                type: "p",
                text: `The minimum wage in Ireland is €11.30 per hour. This means that no company can pay you less.
                However, depending on the job you take on, the hourly wage can be higher, such as in the Health sector.
                 Carers and childminders usually get paid more than €12.70 an hour.`,
              },
            ],
          },
        ],
      },
    ],
  },
  privacy_policy: {
    title: "Privacy <br> Policy",
    breadcr: ["About Us", ">", `Privacy Policy`],
    title1: "Request for consent",
    text1: `NED College requests permission to use personal information. You
            may request access to this data at any time or tell us to remove it from our records
            unless the data is kept for legal reasons. We will ask you to accept this privacy policy
            during the booking/enrolment process. Your consent can be withdrawn at any time.`,
    text2: "If you have any questions, please contact the school:",
    text3: "Via post",
    text4: "Via e-mail",
    text5: "The Data Protection Commissioner",
    items: [
      {
        title: "Who are we?",
        body: [
          {
            type: "p",
            text: `NED College is a Language Education Centre (LEC) and we are
            responsible for processing any personal information you provide to the
            school via our website www.ned.ie. We respect your rights to privacy
            and the protection of your personal information. The purpose of
            this privacy notice is to explain how we collect and use personal
            information for the provision of our services and the daily running of
            the school and to outline your access to and control over your
            personal information.`,
          },
        ],
      },
      {
        title: "What is personal information?",
        body: [
          {
            type: "p",
            text: `Information which can be used to identify you is known as personal information.`,
          },
          {
            type: "p",
            text: `When you provide personal information to NED College, the school as the data controller of that information is responsible for how and why the information is kept.`,
          },
        ],
      },
      {
        title: "Types of information we may process",
        body: [
          {
            type: "p",
            text: `We collect and process various categories of personal information, including basic information such as name, DOB and contact details. We may also if required or necessary; collect passport and visa details, as well as data relating to medical conditions, special needs and particular requirements. We may also on occasion collect information in photographic or video form (with prior consent).`,
          },
        ],
      },
      {
        title: "How do we process your personal information?",
        body: [
          {
            type: "p",
            text: `The processing of personal information is controlled and regulated by the General Data Protection Regulation (GDPR).`,
          },
          {
            type: "p",
            text: `To comply with GDPR, NED College is obliged to ensure that all personal data is kept up to date, stored safely and destroyed in a secure manner, used only for the purpose it is collected for, protected from loss and unauthorized access and disclosure.`,
          },
        ],
      },
      {
        title: "What do we use your personal information for?",
        body: [
          {
            type: "p",
            text: `We only use your personal information for specific purposes related to the administrative, academic and business management of the school including:`,
          },
          {
            type: "ul",
            list: [
              "School enrolment",
              "To process financial transactions",
              "To assist with visa applications",
              "To order medical or learner protection insurance",
              "For immigration purposes",
              "To book accommodation",
              "For the promotion of the school",
            ],
          },
          {
            type: "p",
            text: `If we wish to use your personal information for some new purpose we must inform you of our plans in advance and seek your prior consent to any new processing.`,
          },
        ],
      },
      {
        title: "Security",
        body: [
          {
            type: "p",
            text: `We are committed to ensuring that your personal information is secure with us. All information submitted directly or via the website is fully protected both online and offline.</p> <p>Only staff members who need the information to carry out a specific job will be allowed access to your personal information. All computers and manual filing systems in which NED College store information are kept in a secure environment.`,
          },
        ],
      },
      {
        title: "Sharing with third parties",
        body: [
          {
            type: "p",
            text: `We will not share your personal information with anyone outside of the school unless we have your express permission. `,
          },
          {
            type: "p",
            text: "Under no circumstances will we sell, rent or share your personal information with a third party.",
          },
        ],
      },
      {
        title: "Transferring information overseas",
        body: [
          {
            type: "p",
            text: `Your personal information may only be transferred outside the EU if you permit us to do so.`,
          },
        ],
      },
      {
        title: "How long do we keep your information for",
        body: [
          {
            type: "p",
            text: `Due to legal and operational obligations/regulations we may keep your personal information for an indefinite period unless you request that it be removed from our system.`,
          },
        ],
      },
      {
        title: "How long do we keep your information for",
        body: [
          {
            type: "p",
            class: "fw-bold",
            text: `Under GDPR you have certain legal rights to control your personal information and the manner in which we process it. This includes:`,
          },
          {
            type: "ul",
            list: [
              "A right to access",
              "A right to correct inaccurate information or update incomplete information",
              "A right to restrict processing",
              "A right to receive information in a portable form",
              "A right to object to processing of data for a purpose for which it was not provided",
              "A right to withdraw your consent at anytime",
              "A right to lodge a complaint with the Data Protection Commissioner",
            ],
          },
        ],
      },
    ],
  },
  services: {
    title: `More than <br> English courses`,
    breadcr: ["Student life", ">", `Services`],
    title2: "Our school offers much more than English courses, we offer the whole experience!",
    items: [
      {
        image: "services_1",
        title: "Extra Activities",
        body: [
          {
            type: "p",
            class: "",
            text: ` NED College believes that time spent outside the classroom is very important to help you to
            improve your language and make new friends. Therefore, we provide activities related to the
            learning process itself, such as our free extra classes covering conversation, pronunciation,
            grammar and how to create your CV, as well as outdoor activities, excursions and trips, such as
            our trip to Morocco. Every month, you can find the full schedule on the notice boards around
            school, our social media channels and on our app.`,
          },
        ],
      },
      {
        image: "services_2",
        title: "NED College App",
        body: [
          {
            type: "p",
            class: "",
            text: `Another big strength to take into account is that NED College offers an incredible app to make
            students' lives easier. NED College App is the best tool to track your academic life, check your
            attendance, ask for holidays, discover what's new on our school calendar, join activities and more.`,
          },
          {
            type: "p",
            class: "fw-bold",
            text: "Download it for free from Google Store or Apple Store and register with your student ID.",
          },
        ],
      },
      {
        image: "services_3",
        title: "Student support",
        body: [
          {
            type: "p",
            class: "",
            text: "At NED College, students can count on having professional support in their own language. Every member of our team has studied abroad and lived the same experience as our students. So they will help you before your arrival in Ireland and during the period studying here.  Our student advisors COMPLETELY understand what living abroad means. They will inform you, from top to bottom, about the English course, visa, accommodation, public transport, work permit and how to get the best out of your experience in Dublin or Limerick.",
          },
        ],
      },
      {
        image: "services_4",
        title: "Temporary Accommodation in Ireland",
        body: [
          {
            type: "p",
            class: "",
            text: "Our aim is to make you feel at home in Ireland. Your accommodation is an extremely important part of your whole experience. The different locations where we accommodate students are all close to public transport, shopping centres, supermarkets and banks. With NED College you can rest assured that we will provide you with quality accommodation that best suits your needs. We work with Host Families, Hostels and Student Accommodation. Talk to our team to see which one best fits your needs and budget.",
          },
        ],
      },
      {
        image: "services_5",
        title: "Airport Pick-up",
        body: [
          {
            type: "p",
            class: "",
            text: "When you arrive in Dublin or Limerick to take our English courses, an Airport Pickup service can be arranged. This service ensures a convenient and safe arrival at your residence, hotel or hostel after landing.",
          },
        ],
      },
      {
        image: "services_6",
        title: "NED College Student Card",
        body: [
          {
            type: "p",
            class: "",
            text: "NED College has its own student card which provides discounts for our students in several shops, restaurants, cinemas and more. All students can get this card and it will be valid for the period of the student’s stay in Ireland. It is free for students and it will guarantee discounts and special offers!",
          },
        ],
      },
      {
        image: "services_7",
        title: "Enrolled Learner Protection",
        body: [
          {
            type: "p",
            class: "",
            text: "In line with current national regulations, all students enrolled on courses 3 months or longer in duration, are obliged to purchase the Learner Protection as part of their course fees. This is an insurance that protects the student’s fees in the unlikely event of something happening to the school.",
          },
        ],
      },
      {
        image: "services_8",
        title: "Health Insurance",
        body: [
          {
            type: "p",
            class: "",
            text: "We will give you support in requesting your Health Insurance after your arrival in Ireland and before going to the Immigration Office. Remember that Medical insurance is compulsory for visa required students.",
          },
        ],
      },
      {
        image: "services_9",
        title: "Induction Day",
        body: [
          {
            type: "p",
            class: "",
            text: "As soon as the student arrives and before starting the course, our team will deliver an induction day. The meeting is of utmost importance for us! It is done to inform students everything about NED College and the experience they are about to start. All these details will be explained in their own languages, so it is fully understood!",
          },
        ],
      },
      {
        image: "services_10",
        title: "English Exams & Certification",
        body: [
          {
            type: "p",
            class: "",
            text: "We think that to set a goal is the best way to motivate and help yourself to reach your objectives. English language exams, such as Cambridge Exams and TIE, are a great motivator to get you studying and working hard during your time in Ireland. Here at NED College we offer exam preparation to help you achieve those important goals. Remember that for NED College students enrolled on a 25 week course, an end of programme examination is mandatory.",
          },
        ],
      },
      {
        image: "services_11",
        title: "Student of the month",
        body: [
          {
            type: "p",
            class: "",
            text: "Every month our teachers nominate one student to be the ‘Student of the Month’. This could be awarded because of your progress, participation in class or maybe you have that special quality that makes you a great student.",
          },
        ],
      },
    ],
    button: "I want more information",
  },
  student_visa: {
    title: "Study and work in Ireland",
    section1: {
      title: "Student Visa & Immigration Rules",
      subtitle: `Obtaining a student visa to reside legally in Ireland for a period exceeding 90 days
            requires the application for an Irish Resident Permit (IRP)`,
      text1: `Please note that certain nationalities are required to apply for a visa before traveling
            to Ireland. A list of these nationalities can be found on the Irish immigration office
            website`,
      text2: `or by contacting the relevant embassy.`,
    },
    section2: {
      title: `When immigrating to Ireland from a non-European country to study English, it is
            essential to have the appropriate documentation in order to ensure a smooth and
            successful visa application process.`,
      subtitle: `The following documents are required for both short-term and long-term English courses:`,
      subtitle_left: `Short-term English Course`,
      list1_left: "Original passport, valid for the entire stay period in the country",
      list2_left: "Return flight tickets to the country of origin",
      list3_left: `Application letter from NED College, including information about the course and
                confirmation that all fees have been paid`,
      list4_left: ` Confirmation letter from the residence, house, hotel or hostel where the student
                will be staying`,
      list5_left: "Financial evidence, ranging from €500 to €700 per month",
      list6_left: "Private health insurance",
      text1: "Some nationalities must apply for the visa before travel to Ireland:",
      subtitle_right: "Long-term English Course",
      list1_right: `Original passport, valid for the entire stay period in the country`,
      list2_right: `Application letter from NED College, including information about the course and
                confirmation that all fees have been paid`,
      list3_right: `Confirmation letter from the residence, house, hotel or hostel where the student
                will be staying`,
      list4_right: `Governmental insurance (mandatory) and private health insurance (optional)`,
      list5_right: `Financial evidence of €3,000 (After 01 July 2023, will be required to show evidence
                that you have access to at least €10,000 per year of your studies. If the duration
                of the course is less than 6 months, you must have access €4,200).`,
      list6_right: `€300 for the visa and IRP registration`,
      text2: `Some nationalities must apply for the visa before travel to Ireland`,
      button: "Check a list of Nationalities",
    },
    section3: {
      title1: " Although the person applying is responsible for getting the Visa,",
      title2: "our student consultants will assist you throughout the application process.",
      button: "I want to speak with an agent",
    },
    section4: {
      title: "Application review",
      text1: "After submitting the necessary documents and paying the fee,",
      text2: "the immigration office will review the documents and grant the residence permit,",
      text3: "which will include recording the applicant's personal data (fingerprints and photo).",
      text4:
        "The entire process typically takes about one hour, although sometimes will be busier. Upon completion, the office will place a stamp in the passport indicating the",
      text5: "residence permit and the work hours allowed (Stamp 2).",
      card_icon: "exclamation",
      card_title:
        "It is important to note that students must always carry their IRP when traveling outside of Ireland and re-entering the country.",
      card_body:
        "Additionally, leaving the country and re-entering as a tourist after a visa has expired is not permitted. Our student consultants are available to assist with the full application process. For more information, please refer to the Irish Immigration Office website and contact your embassy for additional details.",
    },
  },
  terms_and_conditions: {
    title: "Terms and <br> Conditions",
    breadcr: ["About Us", ">", `Terms and conditions`],
    section: {
      title: "NED College Terms and Conditions",
      subtitle: "General",
      list1: ` It is the duty of the student to ensure that he/she reads and understands the Terms
              and Conditions contained herein. By signing the terms and conditions the student
              agrees to the information indicated in the offer email (package items, fees, course
              commencement date) made by NED College, the student enters into a contract with NED
              College for the period indicated in the ‘Quotation email', detailed “Course
              Commencement Date” through “Course Completion Date”, subject to the clauses described
              herein.`,
      list2: `The courses will be reserved after signing the terms and conditions and after the full
              payment of your course fees. The course fee must be fully paid at least 3 months
              before the course commencement date. <br />
              <b>PLEASE NOTE:</b> A valid passport must be submitted along with flight tickets.`,
      list3: `Due to the possibility of unforeseen circumstances during the school year; student
              timetables may change during the year. Every effort is made in order to avoid such
              occurrences. However, should this be necessary, the Academic Department of NED College
              is not liable under any circumstances, and therefore no compensation or form of refund
              would be given should any such action be necessary.`,
      list4: `All new NED College students are required to complete the Class placement test and
              speaking test. Should this not be completed before the planned start date, the student
              will not be allowed to start from the original (planned) commencement date.`,
      list5: `The student will be placed according to ability and availability at each level. The
              student may request to change level, but this will only be approved once the student
              has achieved the necessary standard and if space is available. If the student has
              achieved the necessary level, but space is not available, the student must continue to
              attend class until he/she receives an email placing them in the new class. The school
              will endeavour to ensure that this potential wait is kept to a minimum. If the student
              wants to move from afternoon class to morning class, an administration fee will be
              charged. If the student wants to move from morning class to afternoon class, there is
              no administration fee applied. In both cases, a waiting period may still apply. <br />
              <b>PLEASE NOTE:</b> Under no circumstances are morning fees refundable.`,
      list6: `NED College reserves the right to suspend or expel any student should he/she not
              fulfil the necessary requirements required of students. The same applies for
              behavioural disruption to the general conduct of NED College. In both instances no
              refund of fees will be applicable or given.`,
      list7: `It is forbidden for students to film or take pictures during class, without the
              express permission of the teacher.`,
      list8: `The student accepts the condition that the textbook is mandatory and must be in place
              to access class. The coursebook is compulsory at all levels and the student accepts
              that he/she must have it in order to attend class. Students may need to buy more than
              one book during the duration of their course. Any student who does not have the book
              included in the registration must acquire it during the first week of the course. If
              any given level or course requires two different books, NED College will not be
              responsible to provide more books than the student has purchased or is included in the
              registration. NED College does not provide an exchange of new or used books under any
              circumstances.`,
      list9: `Once the course is fully paid, students are expected to arrive in Ireland within a
              year. There will be an additional fee if students decide to extend their arrival date.`,
      list10: `In order to improve the quality of courses and services, NED College reserves the
              right to modify the Terms and Conditions and any aspect of the courses without prior
              notice. However, if such actions are taken, students will be notified in order to
              comply with new clause(s).`,
      list11: `NED College will not be responsible for payments made to accounts other than the
              official means of payment of NED College, which includes Flywire online payment
              platform, NED College International office account (Chile, Uruguay and Malaysia) and
              direct transfers to the bank account of NED College (Account name registered as
              National Employee Development Training Center). NED College also has a partner in
              Brazil called LF TURISMO. <br />
              <b>PLEASE NOTE:</b> Any request for payment to another bank account must immediately
              be communicated directly to NED College's Administration team (info@ned.ie). NED
              College will not be responsible for amounts paid to destinations other than those
              mentioned above.`,
      list12: ` All the availability regarding Course Commencement Date(s), accommodation, and airport
              transfers need to be checked and booked in advance. NED College will not be
              responsible for booking any services if the availability for it was not confirmed in
              advance.In order to guarantee enrollment space, accommodation & airport transfer (if
              included in the package); flight ticket confirmation needs to be sent at least 3
              months in advance of planned course commencement/arrival. If the student sends flight
              tickets without previous confirmation from NED College, we will not be responsible for
              the booking of any service and no refunds will apply.<br />
              <b>PLEASE NOTE:</b> Students must receive confirmation from NED College regarding
              availability, prior to the booking of flight tickets.`,
      list13: `Promotional package items are non refundable nor can they carry over to the next
              course. This is true if these have been used or not.`,
      list14: `NED College does not provide private parking at any premises. <br />
              <b>PLEASE NOTE:</b> NED College does not provide parking for cars, scooters, bicycles,
              etc. NED College bears no responsibility for the loss, damage or theft of personal
              belongings.`,
      list15: `Students participating in short term courses and students who are non-student visa
              required, also must comply with the policies of NED College outlined in this document.`,
      list16: `The use of NED College's premises for commercial purposes is strictly prohibited and
              forbidden.<br />
              <b>PLEASE NOTE:</b> Under no circumstances can students sell food or other items
              inside or in the vicinity of any of NED College's premises.`,
      list17: `<b>Students enrolled on IELTS exam preparation course:</b> it is the student's
              responsibility to have the minimum level required in order to take IELTS exam
              preparation course. In the event where the level does not meet required minimum, there
              will be no grounds of compensation or refund.`,
    },
    items: [
      {
        title: "Live on-line classes in the event of temporary school closure",
        body: [
          {
            type: "p",
            text: `In the event of temporary school closure due to unforeseen circumstances or force majeure; no
            refund will apply as NED College will continue to support students in all aspects of their
            course. NED College classes will be delivered via live Zoom sessions or similar online
            platforms. Classes will be divided into different levels. Students must follow the teacher's
            instructions.`,
          },
          {
            type: "p",
            text: `NED College's online classes allow teachers and students to interact in a dynamic way, so the
            teacher may ask you to turn on your microphone and/or camera to interact with him/her and the
            class, answer questions displayed on the screen, follow a digital board to make examples, etc.
            Sharing your personal data/log-ins with individuals not enrolled at NED College is strictly
            prohibited.`,
          },
          {
            type: "b",
            text: `PLEASE NOTE:`,
          },
          {
            type: "p",
            text: `NED College has no responsibility to provide students with electronic
            devices, internet connectivity, etc. This is the student's responsibility.`,
          },
          {
            type: "p",
            text: `Sharing your personal data/log-ins with individuals not enrolled at NED
            College may result in expulsion and no refund of fees will be applicable or given.`,
          },
        ],
      },
      {
        title: "Biometric data collection",
        body: [
          {
            type: "p",
            text: `All students are asked to consent to the collection of biometric data by NED College. After their arrival in Ireland, the student must
          present themselves in the supporting department in order to have their biometric data registered on the NED College system, if the student
          does not present for registration, classes will be delayed, which may result in a loss of attendance. The biometric data collected will be
          used exclusively for the calculation and maintenance of attendance records in-line ILEP attendance requirements and those of Irish immigration
          authorities.`,
          },
        ],
      },
      {
        title: "Punctuality, attendance, absenteeism & expulsion",
        body: [
          {
            type: "p",
            text: `All students are required to attend classes and maintain a minimum attendance rate of 85%. Each classroom contains a biometric reader, which students must present to at the start of class (or upon arrival) to register for attendance for the day. At the end of class (or as leaving) students must again present to the reader, to complete attendance registration for the day. This biometric data is gathered and stored by NED College exclusively for the calculation and maintenance of attendance records.<br>
          Students must register at all times regardless of being late or not. Tardiness is not tolerated and any student who arrives after class has commenced will lose attendance for the first half of class. Similarly, if a student leaves class early, he/she will lose attendance for the second half of class. If students forget to present to the reader, they will lose attendance.<br>
          If a student is unable to complete his/her course with a minimum of 85% attendance, NED College will not be responsible for any issue this may cause during the potential renewal of the student's visa. Attendance is a provision of the student's visa and non-attendance will result in expulsion. By law NED College must notify Irish Immigration authorities of all expulsions. Expulsion may impact a student's permission to study/work in Ireland.<br>
          Student absence will be recorded as non-attendance unless a verified medical certificate is presented. Students attending their end of course examinations or appointment(s) at immigration offices, will not result in absences from school, however proof must be presented to NED College's Academic Dept (Dublin: support.dublin@ned.ie Limerick: support.limerick@ned.ie).<br>
          In the case of illness: students are expected to provide a medical cert/Doctor's note to cover absence(s). This documentation should be presented to NED College Academic Dept at the student's earliest opportunity. This information and documentation will be analysed and verified and a decision will be made regarding attendance.<br>
        `,
          },
          {
            type: "b",
            text: `PLEASE NOTE:`,
          },
          {
            type: "p",
            text: `Only medical certs/Doctor's notes issued in Ireland can/will be accepted.`,
          },
          {
            type: "p",
            text: `All documentation regarding missing attendance is checked and verified by the Academic Dept. Students who present false documentation, will receive warnings and depending on the seriousness of the situation; immigration authorities may be informed.`,
          },
        ],
      },
      {
        title: "Attendance policy",
        body: [
          {
            type: "p",
            text: `In line with ILEP requirements and those of Irish immigration authorities, students at NED College are expected to maintain an attendance rate of at least 85% at all times during their course. If the school notices that the attendance of a student is falling below what is required, there are a number of steps the school is obliged to take:`,
          },
          {
            type: "ul",
            list: [
              {
                title: "First Attendance Warning:",
                text: "First attendance warnings are issued to students in cases where it is noted that attendance has dropped for the first time during the student's course.",
              },
              {
                title: "Second Attendance Warning:",
                text: " Students who have received a first attendance warning, but who have not taken steps to improve their attendance will be sent a second attendance warning.",
              },
              {
                title: "Third Attendance Warning:",
                text: "In the event that a student has already received two warnings, but no effort has been made to improve attendance, then he/she will receive a third warning. Students who receive a third attendance warning need to communicate to our admin team by email to explain their situation.",
              },
              {
                title: "Fourth Attendance Warning:",
                text: "This is the final step prior to expulsion. A student who receives a fourth attendance warning has a history of poor attendance and has been well informed of the consequences of not meeting attendance requirements. NED College retains the right to not issue the fourth attendance warning in cases where students have made no effort to engage with the school or improve attendance.",
              },
            ],
          },
          {
            type: "p",
            text: "At any stage during the process outlined above, students are welcome and encouraged to discuss any issue(s) which may be contributing to them not being able to meet attendance requirements. The school has an open-door policy, numerous personnel available, as well as various methods of communication options open to students.",
          },
          {
            type: "p",
            text: "If during the process outlined above, a student;",
          },
          {
            type: "p",
            text: "(A) Does not reply/interact/engage with the school;",
          },
          {
            type: "p",
            text: "(B) Continues to not attend class;",
          },
          {
            type: "p",
            text: "(C) If a student is unable to reach 85% attendance before the end of his/her course - NED College retains the right to expel them without issuing all warnings.",
          },
        ],
      },
      {
        title: "Holidays & breaks",
        body: [
          {
            type: "p",
            text: "All NED College students receive 2 weeks of holidays in the Christmas period and 1 week for Easter as the school is closed during these periods. All other holiday periods for students must be requested by students through the NED College App. The granting of Holidays is subject to conditions and at the discretion of the school.",
          },
          {
            type: "p",
            text: "To be approved for holidays: Students must finish a minimum of 8 weeks of their course, maintain 85% of attendance, and must have more than 4 weeks before the end of their course. The requested holiday period must start on Monday and finish on Friday.",
          },
        ],
      },
      {
        title: "End of programme examinations",
        body: [
          {
            type: "p",
            text: "End of programme examinations are mandatory for all students at NED College. This fact is clearly communicated to students upon course purchase and is in accordance with Irish Immigration Authorities’ regulations. The student agrees that they understand that the end of programme examination is mandatory and agrees to sit the exam with a possible additional charge. Failure to sit this mandatory examination may result in the student being expelled and/or immigration authorities being notified. It also may affect the student’s right to remain in Ireland under Irish immigration authorities regulations.",
          },
          {
            type: "b",
            text: `PLEASE NOTE:`,
          },
          {
            type: "p",
            text: "Each course requires examination purchase. Examination registration cannot be carried from one course to another.",
          },
        ],
      },
      {
        title: "Protection of enrolled learners",
        body: [
          {
            type: "p",
            text: "In line with national regulations, all students enrolled on courses 3 months or longer in duration, are obliged to purchase Learner Protection as part of their course fees. This is an insurance that protects the student’s fees in the unlikely event of something happening to the school.",
          },
          {
            type: "b",
            text: `PLEASE NOTE:`,
          },
          {
            type: "p",
            text: "The insurance policy offered by NED College combines Learner Protection Insurance and Medical Insurance.",
          },
        ],
      },
      {
        title: "Photography & film material",
        body: [
          {
            type: "p",
            text: "By accepting these terms & conditions, students accept that photos or videos from events or classes may be recorded and used for promotional purposes.",
          },
        ],
      },
      {
        title: "Liability",
        body: [
          {
            type: "p",
            text: "NED College accepts no liability in the event that any service contracted (by the student) to be supplied by NED College becomes impossible to supply by reasons of industrial dispute or any other cause outside of the control of NED College (force majeure).",
          },
          {
            type: "p",
            text: "NED College will not be liable for loss, damage, or injury to persons or property however so caused, save where the liability is expressly imposed beyond exclusion by statute. All accommodation and airport pick-up cancellations occurring 30 days or less prior to arrival date are non-refundable (this includes cancellations by airlines). The same applies if student(s) have to shorten their stay – payment is non-refundable. So called “no shows”, failure to arrive or postponement incurs charges in full from the booking date.",
          },
          {
            type: "b",
            text: `PLEASE NOTE:`,
          },
          {
            type: "p",
            text: "Accommodation, airport pick-up or any additional services are non-transferable if cancelled or postponed.",
          },
        ],
      },
      {
        title: "Cancellation and refund policy",
        body: [
          {
            type: "b",
            text: "PLEASE NOTE:",
          },
          {
            type: "p",
            text: "- If you have acquired a course through agencies or other partners, kindly reach out to them to adhere to their established refund policy.",
          },
          {
            type: "p",
            text: "- Please be advised that any deposit(s) is non-refundable.",
          },
          {
            type: "p",
            text: "- Refunds are not applicable once the course has commenced. Should you choose to withdraw from the course, kindly submit your request via email to the school's support email address. Consideration for refunds, partial refunds of fees, or issuance of credit notes can only be entertained before the student arrives in Ireland or, or the case of a renewal course, before the student receives the enrollment letter.",
          },
          {
            type: "p",
            text: "If this is not the case, requests may be processed under the following circumstances:",
          },
          {
            type: "p",
            text: "(A) Refusal of a Stamp 2 student visa on appeal where the student has no prior convictions or immigration history. The rejection letter issued by immigration will be evaluated by NED College to assess grounds for any refund.",
          },
          {
            type: "p",
            text: "(B) Illness or disability occurring after the student has accepted admission to NED College by completing payment. In any such instance, a doctor’s certificate would be required.",
          },
          {
            type: "p",
            text: "(C) Upon arrival, should any student be refused access into Ireland for any reason, the rejection letter issued by the GNIB will be evaluated by NED College to assess grounds for any refund",
          },
          {
            type: "p",
            text: "(D) Course cancellation and no alternative course available at NED College",
          },
          {
            type: "p",
            text: "(E) Family bereavement",
          },
          {
            type: "p",
            text: "(F) When a student decides to cancel his/her course before finishing the full payment of the programme",
          },          {
            type: "ol",
            list: [
              "A refund will only be granted in respect of fees which have been paid within 1 year in which any of (A) to (F) occurs. There is no automatic right to a refund of fees if, at any time, a student changes his/her mind about studying at NED College.",
              "A 25% Administration Fee of the total amount will be deducted from all refunds given.",
              "Refunds cannot be made after the enrollment letter has been issued after arrival in Ireland and/or a Student visa has been granted. The same applies to any additional services issued, such as medical insurance, learner protection, accommodation, and the end-of-program exam.",
              "PLEASE NOTE: The enrollment letter will be issued one month before the student's commencement date.",
              "All course cancellation requests involving refund requests should be directed via your sales advisor, or by using NED College’s Credit Note and Refund request 2 months before the course commencement date.",
              "Students found to have provided false or incorrect information, resulting in their visa being refused, shall not be entitled to any form of refund.",
              "The refundable amount will be calculated based on the amount NED College has received. If a student paid through any international bank or payment platform(s) such as Stripe, TransferMate, PayPal, Flywire, Western Union, or Global 66 there might be a fee of up to 6% that was applied to the payment which will be deducted from the refundable amount in addition to the administration fee of 25%.",
              "All student refund applications are reviewed weekly by the administration team, if approved, payment will be made to the student within 2 months in which any of (A) to (F) occurs.",
              "To comply with Anti Money Laundering Legislation, refunds/transfers can only be made to the person who booked/paid for the course. The refunds will be sent to the student in Euros or using the same currency the student had paid and it is the responsibility of the student to provide appropriate bank account details to receive funds from Ireland (IBAN and other necessary information). If NED College is provided with incorrect bank details and funds bounce back - any additional bank transaction fees will be deducted ahead of the next attempt to make the bank transfer.",
              "CREDIT NOTE In place of a refund, students can request a credit note in which any of (A) to (F) occurs. Credit notes can be requested through ‘Credit Note and Refund request’, the NED College administration team will review and issue a credit note of the amount NED College has received.",
            ],
          },
          {
            type: "b",
            text: "PLEASE NOTE: Students are not eligible to convert the credit note into cash or request a bank transfer.",
          },
          {
            type: "p",
            text: "Students can use the credit in the future or transfer it to someone else. The amount of the credit note will be shown only for the amount NED College has received, therefore it does not guarantee the same promotional package price and details the student had originally purchased.",
          },
          {
            type: "p",
            text: "Credit notes are valid for one year from the date of issue. Once this period expires, the credit note becomes invalid. Credit notes are non-refundable at all times and can only be issued and redeemed once per payment. If a person who has redeemed a credit note wishes to cancel or request another credit note, the request will be denied. Credit notes cannot be divided between two different campuses.",
          },
          {
            type: "p",
            text: "To receive a credit note, students must notify NED College a minimum of 2 months before the course commencement date and/or within 1 year of the first payment being made. When it’s approved, the student programme will be cancelled immediately, and the contract with NED College will no longer be valid",
          },
          {
            type: "p",
            text: "To redeem a credit note, students must notify NED College a minimum of 3 months before the desired course commencement date. The person who is going to redeem the credit note must follow the current price list and promotional price.",
          },
          {
            type: "p",
            text: "If there is any excess balance will be issued as a credit note.",
          },
          {
            type: "p",
            text: "To transfer a credit note; students must sign a credit note transfer agreement (this document can be provided by your sales advisor). This signed credit note transfer agreement should be provided to NED College along with the original credit note.",
          },
          {
            type: "b",
            text: "After receiving a Stamp 2 student visa, students cannot request a credit note. NED College has a right to refuse to issue a credit note also asking students to provide appropriate evidence such as an Irish entry visa rejection letter, medical note and the flight boarding pass. Any service fee that is used, such as Insurance and Learner Protection, End of programme Exam, and Textbook, will be deducted from the credit amount.",
          },
        ],
      },
    ],
  },
  careers: {
    title: "Careers",
    breadcr: ["About us", ">", "Careers"],
    section1: {
      title: "Welcome to NED College Careers!",
      text: "Discover a world of opportunities at NED College, where diversity thrives and learning knows no boundaries. Join our multicultural team and kickstart your international career. With us, you'll not only excel professionally but also immerse yourself in a vibrant community, grow your skills, and enhance your English language fluency. We invite you to explore our open positions and be a part of our mission to provide top-notch education and support to our students while nurturing your own career aspirations.",
    },
  },
  studywithus: {
    header: {
      title: `<span class="text-green">Study English and Work </span>
        <span class="text-white"> in Ireland with NED College</span>`,
      subtitle: `Take the first step now!`,
    },
    form: {
      title: "Take the first step now!",
      name: `Your Full Name`,
      email: `Your e-mail`,
      whatsapp: `Your WhatsApp`,
      nationality: `Please select your nationality`,
      campus: `Which campus do you want to study?`,
      button: `Get a quotation!`,
    },
    section1: {
      title: `Why should you choose Ireland for your exchange program?`,
      items: [
        "Opportunity to <b>study and work</b> legally",
        "One of the <b>highest minimum wages</b> in Europe",
        "<b>Easy visa process,</b> obtained directly in Ireland upon arrival",
        "Chance to <b>travel to various European countries</b>",
      ],
    },
    section2: {
      title: `<span class="text-green">For over 10 years</span>, <span class="text-blue5">NED College</span> has been an English school in Ireland welcoming exchange students from <span class="text-blue5">over 30 different nationalities</span>`,
      subtitle: `With two campuses in the country, we provide an <span class="text-blue5">extraordinary experience</span> for our students and offer excellent academic education`,
      button: "I want to study in NED College",
    },
    section3: {
      dublin: {
        title: "Dublin Campus",
        subtitle:
          "Located in the heart of the city, easily accessible by public transport, within two historic buildings dating back to 1750.",
        button: "I want to study in Dublin",
      },
      limerick: {
        title: "Limerick Campus",
        subtitle:
          "Situated in the centre of Limerick, with easy walking access to the city's main tourist attractions, housed in a building with modern facilities and accessibility.",
        button: "I want to study in Limerick",
      },
    },
    section4: {
      title: "Why should you choose NED College for your exchange program in Ireland?",
      items: [
        {
          img: "/landings/landing_0823/imgs/services_01.png",
          text: "<b>Welcome kit</b>, meeting, and tour",
        },
        {
          img: "/landings/landing_0823/imgs/services_02.png",
          text: "<b>Cultural</b> immersion",
        },
        {
          img: "/landings/landing_0823/imgs/services_03.png",
          text: "Support in your <b>native language</b>",
        },
        {
          img: "/landings/landing_0823/imgs/services_04.png",
          text: "Activities and <b>trips</b>",
        },
        {
          img: "/landings/landing_0823/imgs/services_05.jpeg",
          text: "<b>Qualified</b> teachers",
        },
        {
          img: "/landings/landing_0823/imgs/services_06.jpeg",
          text: "<b>Extra-curricular</b> classes",
          description: "(Conversation, Pronunciation, etc.)",
        },
        {
          img: "/landings/landing_0823/imgs/services_07.jpeg",
          text: "<b>Workshops</b> and professional courses",
        },
        {
          img: "/landings/landing_0823/imgs/services_08.jpeg",
          text: "<b>Student Support</b> in Ireland",
        },
        {
          img: "/landings/landing_0823/imgs/services_09.jpeg",
          text: "<b>Flexible</b> payment options",
        },
        {
          img: "/landings/landing_0823/imgs/services_10.png",
          text: "Exclusive <b>NED College app</b>",
        },
        {
          img: "/landings/landing_0823/imgs/services_11.png",
          text: "Discounts at <b>partner companies</b>",
        },
      ],
      button: "I want to study at NED College",
    },
    section5: {
      title: "What our students are saying?",
      button: "Get a quotation!",
    },
    section6: {
      button: "I want to study in NED College",
    },
  },
  studywithus_thanks: {
    title: `Thank you for your interest in <span class="text-green">NED College!</span>`,
    subtitle: `We have received your details and will <span class="text-green2">be in touch shortly.</span>`,
    description: `Now that you've taken the first step, <span class="text-blue2">take the opportunity to discover more about our
      English school</span> and explore what life is like as an exchange student with NED College.`,
    socials_title: "Follow our social networks",
  },
  partnership: {
    topbar: "<b>A <span class='text-green3'>new journey</span> is about to begin</b>",
    header: {
      title: `Welcome to our <span class='text-green3'>partnership area</span>`,
      subtitle:
        "Here you can find everything about us, including pictures, videos, brochures, and more.",
    },
    about: {
      title: `About <span class="text-blue2">our school</span>`,
      subtitle:
        "We are an English Language School located in Ireland since 2011. With campuses in Dublin and Limerick, we have been assisting students from around the globe in enhancing their English skills and connecting with new people and experiences.",
      text: "Our dedicated team has been diligently working to provide the best for our students, going beyond just English classes. Our goal is to offer an unforgettable study abroad experience. To achieve this, we provide personalized student support, highly qualified teachers, and an immersive cultural environment.",
    },
    accreditations: "Associates <span class='text-blue2'>& Accreditations</span>",
    media: {
      title: "Media <span class='text-green2'>Library</span>",
      subtitle:
        "Here, you will find information to get to know us better. Discover details about our campuses, mission, courses, levels, student life, visa information, extra activities, and classes.",
      text: "Our calendar includes course start dates, holidays, and official exam dates. And, of course, you'll get an up-close look at our school through our images and videos.",
      items: [
        {
          title: "NED College Brochure",
          description:
            "Download here our digital brochure, which includes details about our campuses, mission, courses, student life, and extra activities and classes",
          link: "#brochure",
        },
        {
          title: "Pricelist",
          description: "Download the annual NED College Pricelist 2023 / 2024",
          link: "#pricelist",
        },
        {
          title: "Calendar",
          description: "Download our 2024 calendar for Dublin and Limerick",
          link: "#calendar",
        },
        {
          title: "Video Gallery",
          description:
            "Download high-quality videos of our campuses, students, activities and more",
          link: "#videogallery",
        },
        {
          title: "Image Gallery",
          description:
            "Download high-quality images of our campuses, students, activities and more",
          link: "#imagegallery",
        },
        {
          title: "Graphic Material",
          description: "Download our logos and graphic material",
          link: "#graphicmaterial",
        },
      ],
    },
    brochure: {
      title: "NED College Brochure",
      subtitle:
        "Download here our digital brochure, which includes details about our campuses, mission, courses, student life, and extra activities and classes",
      items: [
        {
          title: "English version",
          image: "brochure-en",
          link: "https://nedcollege-my.sharepoint.com/:b:/g/personal/anderson_ned_ie/EWme5bnmNOtMqXymLGwRyQQBs9YC938Zc_Y1ZypepuQv-Q?e=LyUUQV",
        },
        {
          title: "Spanish version",
          image: "brochure-es",
          link: "https://nedcollege-my.sharepoint.com/:b:/g/personal/anderson_ned_ie/EZUVnEk1BFROtqdDw4wq5lcBaDPXGUdZ-khAP09SUqHdmQ?e=gTx9lS",
        },
        {
          title: "Portuguese version",
          image: "brochure-pt",
          link: "https://nedcollege-my.sharepoint.com/:b:/g/personal/anderson_ned_ie/EV95jhfHGhtBgEMYH_fQK6cBSuzRDRTHBax9cvYTdrrzaw?e=AIdF3L",
        },
        {
          title: "Chinese version",
          image: "brochure-en",
          link: "https://nedcollege-my.sharepoint.com/:b:/g/personal/anderson_ned_ie/Eb-YCRFk3jdPtUF3cbO4ye0BMnIXbUcuGCu9_0WG_ZatgA?e=Jtc9Ou",
        },
        {
          title: "Mongolian version",
          image: "brochure-mg",
          link: "https://nedcollege-my.sharepoint.com/:b:/g/personal/anderson_ned_ie/ESAgdnGY7H1Ei60cWc5_IW8BrkpiBiXulx9fu3tkSm4fTQ?e=cPpHsA",
        },
      ],
    },
    pricelist: {
      title: "Pricelist",
      subtitle: "Download the annual NED College Pricelist 2023 / 2024",
      items: [
        {
          title: "Pricelist 2023 / 2024",
          image: "pricelist",
          link: "https://docs.google.com/presentation/d/e/2PACX-1vT8CHNyzX3jCOfTEKofimlXBiOZb7mHjfk1gu7Kp-pfYKxjK5MErzKMYIL4pOZMbvYEscT3s8Ciko8F/pub?start=false&loop=false&delayms=3000",
        },
      ],
    },
    calendar: {
      title: "Calendar",
      subtitle: "Download our 2024 calendar for Dublin and Limerick",
      items: [
        {
          title: "Dublin and Limerick version",
          image: "calendar",
          link: "https://nedcollege-my.sharepoint.com/:b:/g/personal/anderson_ned_ie/EUvJOgqHKBlKsgtKtIKEmlgBAzeJbBE6Ben6I7R6ZOOLEw?e=7zcxtQ",
        },
      ],
    },
    videos: {
      title: "Video Gallery",
      subtitle: "Download high-quality videos of our campuses, students, activities and more",
      items: [
        {
          title: "Institutional Dublin",
          image: "institutional-dublin",
          link: "https://nedcollege-my.sharepoint.com/:v:/g/personal/anderson_ned_ie/ET7zhcWRP9pPogf3nnF5d7YBB5U4F_6Z92KcUNdgPcVHRw?e=wbcbUT",
        },
        {
          title: "Institutional Limerick",
          image: "institutional-limerick",
          link: "https://nedcollege-my.sharepoint.com/:v:/g/personal/anderson_ned_ie/EUMgORzPrmVDvDKMwybnIBYBtO6wUCqmGURnenlLwlt_dA?e=HW7V8P&nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZyIsInJlZmVycmFsQXBwUGxhdGZvcm0iOiJXZWIiLCJyZWZlcnJhbE1vZGUiOiJ2aWV3In19",
        },
        {
          title: "Eaquals 2023",
          image: "eaquals-2023",
          link: "https://nedcollege-my.sharepoint.com/:v:/g/personal/anderson_ned_ie/ETqnTHuHZ4pLtzrd0DSOk20BsU_X3hibBVdlUHJ3cJ1C1A?e=ISwNMi",
        },
        {
          title: "NED College 10 years",
          image: "ned-10-years",
          link: "https://nedcollege-my.sharepoint.com/:v:/g/personal/anderson_ned_ie/EadXawYqmcZCiDRveuslLoUBFxFPUWA5xz69agcCZ7feaQ?e=GNOL0g",
        },
        {
          title: "BeNED 2023",
          image: "bened-2023",
          link: "https://nedcollege-my.sharepoint.com/:v:/g/personal/anderson_ned_ie/EfdsWeUiEPlNgXDt7HNygDEB5zMstUNOMOxCey02qHwPMg?e=qn8TK4",
        },
      ],
    },
    images: {
      title: "Image Gallery",
      subtitle: "Download high-quality images of our campuses, students, activities and more",
      dublin: {
        title: "Dublin Campus",
        link: "https://nedcollege-my.sharepoint.com/:f:/g/personal/anderson_ned_ie/Eho8B1G9ahRLtQf3B5bNoNMBqHBBB_6FdCf9Qr2PBkZtcA?e=BNYHez",
        items: [
          "gallery-dublin-1",
          "gallery-dublin-2",
          "gallery-dublin-3",
          "gallery-dublin-4",
          "gallery-dublin-5",
          "gallery-dublin-6",
          "gallery-dublin-7",
          "gallery-dublin-8",
          "gallery-dublin-9",
          "gallery-dublin-10",
          "gallery-dublin-11",
          "gallery-dublin-12",
          "gallery-dublin-13",
          "gallery-dublin-14",
        ],
      },
      limerick: {
        title: "Limerick Campus",
        link: "https://nedcollege-my.sharepoint.com/:f:/g/personal/anderson_ned_ie/Eh__OO3JlBdPvJwEXCR2RDwBA66bishS4TNCPr1Nus8Zjw?e=FUKHU9",
        items: [
          "gallery-limerick-1",
          "gallery-limerick-2",
          "gallery-limerick-3",
          "gallery-limerick-4",
          "gallery-limerick-5",
          "gallery-limerick-6",
          "gallery-limerick-7",
          "gallery-limerick-8",
          "gallery-limerick-9",
        ],
      },
    },
    material: {
      title: "Graphic Material",
      subtitle: "Download our logos and graphic material",
      items: [
        {
          title: "Main Complete Version",
          image: "graphic-mat-main-complete-version",
          link: "https://nedcollege-my.sharepoint.com/:f:/g/personal/anderson_ned_ie/Eq8zA9YJsGxAqmnPW-nUhicBzHI724v27zVppJX8Oo0x8Q?e=wV4PS1",
        },
        {
          title: "Main Icon Version",
          image: "graphic-mat-main-icon-version",
          link: "https://nedcollege-my.sharepoint.com/:f:/g/personal/anderson_ned_ie/EoymnbheQ4tIn0aYTrckceYBRV6YcgRff2hHe2fRHyI1gg?e=a6bKhR",
        },
        {
          title: "Secondary Complete Version",
          image: "graphic-mat-secondary-complete-version",
          link: "https://nedcollege-my.sharepoint.com/:f:/g/personal/anderson_ned_ie/EkGxlVnd5Y9EnsS6qOn163UBI0MTzns7NB7VdYI7T7z8ZA?e=HMMbJy",
        },
        {
          title: "Secondary Icon Version",
          image: "graphic-mat-secondary-icon-version",
          link: "https://nedcollege-my.sharepoint.com/:f:/g/personal/anderson_ned_ie/EnSpYoHSDNhKhIv9L5BwzyoB0esoGM9nk6J7hk7C5geirw?e=IUbmsz",
        },
        {
          title: "NED Brand Guidelines",
          image: "graphic-mat-brand-guidelines",
          link: "https://nedcollege-my.sharepoint.com/:b:/g/personal/anderson_ned_ie/EUrSJ4DVF-xIh1Y6TPKUCawBzTHX25c_m2bWFRN6lMGwJQ?e=yYUt58",
        },
      ],
    },
    footer: {
      title: "We want to hear <span class='text-green2'>from you!</span>",
      subtitle:
        "If you want to collaborate with us, do not hesitate to get in touch with us. We are looking forward to hearing your ideas!",
      button: "Contact Us",
    },
    back_to_top: "Go back to top",
  },
  thank_you: {
    title: "Thank you <span class='text-green3'>for getting in touch!</span>",
    subtitle: "Our team will contact you very soon!",
    card: {
      title: "In the meantime",
      body: `Why not find out more about us, explore our Dublin and Limerick campuses, or follow our social media channels`,
    },
  },
  // components
  mapsection: {
    title1: "Student Support in ",
    title2: "your own Language",
    text: "At NED College, we will provide support to our students before they arrive and during their period of study in Ireland. All information about the course, visa, accommodation, public transport, work permit and how to get the best out of your experience in Dublin and Limerick will be provided.",
    button: "Talk to our agents!",
  },
  splitted_center: {
    title1: "Choosing",
    title2: "between",
  },
  certifications: {
    title: "Accreditations",
    text: "NED College is proudly associated with great institutions to guarantee the high quality standards our students look for.",
    cta: "Check out our accreditations",
  },
  testimonialsection: {
    title: "Student Testimonials",
  },
  teamsection: {
    title: "Meet our sales team",
    text1: "Campuses / Offices",
    text2: "Look for your agent here",
    text3:
      "We pride ourselves on having a diverse and multicultural sales team dedicated to helping students from all over the world come and experience the amazing opportunities that Ireland has to offer. Whether you are looking to improve your language skills, explore a new culture, or pursue your academic or professional aspirations, our team is here to support you every step of the way.",
    locations: [
      {
        value: "dublin",
        label: "Dublin Campus",
      },
      {
        value: "limerick",
        label: "Limerick Campus",
      },
      {
        value: "chile",
        label: "Chilean Office",
      },
      {
        value: "uruguay",
        label: "Uruguayan Office",
      },
    ],
    team: [
      {
        image: "staff_22",
        name: "Alam Grace",
        subtitle: "Sales Agent Paraguay",
        flag: "paraguay",
        location: "dublin",
      },
      {
        image: "staff_default",
        name: "Amarsaikhan Batsambuu",
        subtitle: "Sales Agent Mongolia",
        flag: "mongolia",
        location: "dublin",
      },
      {
        image: "staff_default",
        name: "Daniela Peña",
        subtitle: "Sales Agent Mexico",
        flag: "mexico",
        location: "dublin",
      },
      {
        image: "staff_default",
        name: "Beatriz Cassão",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "dublin",
      },
      {
        image: "staff_1",
        name: "Carolina Rojas",
        subtitle: "Sales Agent Costa Rica",
        flag: "costa_rica",
        location: "dublin",
      },
      {
        image: "staff_3",
        name: "Deniz Imark",
        subtitle: "Sales Agent Turkey",
        flag: "turkey",
        location: "dublin",
      },
      {
        image: "staff_17",
        name: "Dhiogo Lunardelli",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "dublin",
      },
      {
        image: "staff_13",
        name: "Irina Nikicink",
        subtitle: "Sales Agent Paraguay",
        flag: "paraguay",
        location: "dublin",
      },
      {
        image: "staff_19",
        name: "Larissa Rossi Samuel",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "dublin",
      },
      {
        image: "staff_18",
        name: "Marina Contreras",
        subtitle: "Sales Agent Chile",
        flag: "chile",
        location: "dublin",
      },
      {
        image: "staff_15",
        name: "Mayra Weber",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "dublin",
      },
      {
        image: "staff_14",
        name: "Otavio Damaceno",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "dublin",
      },
      {
        image: "staff_default",
        name: "Otavio Magdaleno",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "dublin",
      },
      {
        image: "staff_12",
        name: "Tserenlkham Agi",
        subtitle: "Sales Agent Mongolia",
        flag: "mongolia",
        location: "dublin",
      },
      {
        image: "staff_16",
        name: "Vitor Britto",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "dublin",
      },
      {
        image: "staff_10",
        name: "Camila Vogel",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "limerick",
      },
      {
        image: "staff_default",
        name: "Chong Rong",
        subtitle: "Sales Agent Malaysia",
        flag: "malaysia",
        location: "limerick",
      },
      {
        image: "staff_6",
        name: "Erik Beltrane",
        subtitle: "Sales Agent Chile",
        flag: "chile",
        location: "limerick",
      },
      {
        image: "staff_11",
        name: "Gabriela Iramendi",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "limerick",
      },
      {
        image: "staff_default",
        name: "Matheus de Oliveira",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "limerick",
      },
      {
        image: "staff_5",
        name: "Melissa Avila",
        subtitle: "Sales Agent Costa Rica",
        flag: "costa_rica",
        location: "limerick",
      },
      {
        image: "staff_default",
        name: "Rhayane Caldeira",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "limerick",
      },
      {
        image: "staff_7",
        name: "Tiago Wagner",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "limerick",
      },
      {
        image: "staff_default",
        name: "Waldo Ramos",
        subtitle: "Sales Agent Chile",
        flag: "chile",
        location: "limerick",
      },
      {
        image: "staff_default",
        name: "Constanza Del Río",
        subtitle: "Sales Agent Chile",
        flag: "chile",
        location: "chile",
      },
      {
        image: "staff_default",
        name: "Daniela Henríquez",
        subtitle: "Sales Agent Chile",
        flag: "chile",
        location: "chile",
      },
      {
        image: "staff_default",
        name: "Cristal Perez",
        subtitle: "Sales Agent Chile",
        flag: "chile",
        location: "chile",
      },
      {
        image: "staff_2",
        name: "Florencia Hidalgo",
        subtitle: "Sales Agent Chile",
        flag: "chile",
        location: "chile",
      },
      {
        image: "staff_4",
        name: "Eduardo Fernández",
        subtitle: "Sales Agent Chile",
        flag: "chile",
        location: "chile",
      },
      {
        image: "staff_default",
        name: "Natalia Smith",
        subtitle: "Sales Agent Uruguay",
        flag: "uruguay",
        location: "uruguay",
      },
      {
        image: "staff_25",
        name: "Paula Piriz",
        subtitle: "Sales Agent Uruguay",
        flag: "uruguay",
        location: "uruguay",
      },
      {
        image: "staff_23",
        name: "Manuela Amato",
        subtitle: "Sales Agent Uruguay",
        flag: "uruguay",
        location: "uruguay",
      },

      {
        image: "staff_default",
        name: "Anudari Erdenetsogt",
        subtitle: "Sales Agent Mongolia",
        flag: "mongolia",
        location: "dublin",
      },
      {
        image: "staff_default",
        name: "Charles Michael Osowski",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "limerick",
      },
      {
        image: "staff_default",
        name: "Francine Mendez",
        subtitle: "Sales Agent Costa Rica",
        flag: "costa_rica",
        location: "limerick",
      },
      {
        image: "staff_default",
        name: "Germano Santana",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "dublin",
      },
      {
        image: "staff_default",
        name: "Ho Li Peng",
        subtitle: "Sales Agent Malaysia",
        flag: "malaysia",
        location: "Limerick",
      },
      {
        image: "staff_default",
        name: "Ingryd Lima",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "dublin",
      },
      {
        image: "staff_default",
        name: "Les Monárdez",
        subtitle: "Sales Agent Chile",
        flag: "chile",
        location: "chile",
      },
      {
        image: "staff_default",
        name: "Manuela mardones",
        subtitle: "Sales Agent Chile",
        flag: "chile",
        location: "chile",
      },
      {
        image: "staff_default",
        name: "Marcos Souza",
        subtitle: "Sales Agent Brazil",
        flag: "brazil",
        location: "limerick",
      },
      {
        image: "staff_default",
        name: "Melissa Rojas",
        subtitle: "Sales Agent Costa Rica",
        flag: "costa_rica",
        location: "dublin",
      },
      {
        image: "staff_default",
        name: "Tiffany Chong Nyet Jun",
        subtitle: "Sales Agent Malaysia",
        flag: "malaysia",
        location: "dublin",
      },
      {
        image: "staff_default",
        name: "Wee Yee Lau",
        subtitle: "Sales Agent Malaysia",
        flag: "malaysia",
        location: "dublin",
      },
    ],
  },
  topbar: {
    button: "Get a quotation!",
    icon: "EN",
  },
  contactform: {
    name: "Your Full Name",
    address: "Your Email Address",
    phone: "Your Phone Number",
    label1: "Please select your nationality",
    label2: "Please select the country you are currently living in",
    label3: "Where are you at in planning your English study abroad?",
    label5: "Select the Programme you are looking for",
    label4: "Which campus do you want to study?",
    message: "If you want to write something, feel free",
    steps: [
      {
        value: "Just researching and trying to understand about studying abroad",
        label: "Just researching and trying to understand about studying abroad",
      },
      {
        value: "I know how much I can invest, but I don't know the destination and/or school",
        label: "I know how much I can invest, but I don't know the destination and/or school",
      },
      {
        value: "Financially prepared and deciding which school to study in",
        label: "Financially prepared and deciding which school to study in",
      },
    ],
    campuses: [
      { value: "dublin", label: "Dublin campus" },
      { value: "limerick", label: "Limerick campus" },
      { value: "didnt_decide", label: "I did not decide yet" },
    ],
    programmes: [
      { value: "first_general", label: "First course - General English" },
      { value: "first_ielts", label: "First course - IELTS Preparation Course" },
      { value: "ren_general", label: "Renewal - General English Course" },
      { value: "ren_ielts", label: "Renewal - IELTS Preparation Course" },
    ],
    button: "Submit!",
    success_msg: "Thanks, your message has been sent!",
  },
  footer: {
    title: `Download our Brochure`,
    text: `Get to know everything about studying in Ireland with NED College!`,
    button: `Download now`,
    ireland: `Republic of Ireland`,
    download_d: `Download our Dublin APP `,
    download_l: `Download our Limerick APP `,
    opening: `Opening hours`,
    days: "Monday to Friday",
    days1: "from 9:00 to 16:30",
    email: "Email",
    refund: `Credit Note and Refund request`,
    terms: `Terms & conditions`,
    privacy: `Privacy Policy`,
    download: `Download our Brochure `,
    subtitlemodal: `Get to know everything about studying in Ireland with NED College!`,
  },
};
