<template>
  <div
    class="img-container"
    :style="style"
    :class="{
      'responsive-sm': withMobile,
      contain: contain,
    }"
    v-if="background"
  >
    <slot />
  </div>

  <picture v-else>
    <source :srcset="`/imgs/webp/${fileName}.webp`" type="image/webp" />
    <source :srcset="`/imgs/png/${fileName}.png`" type="image/jpeg" />
    <img :src="`/imgs/png/${fileName}.png`" :alt="fileName?.replace('_', ' ')" />
  </picture>
</template>

<script>
export default {
  props: {
    fileName: {
      type: String,
      default: null,
    },
    contain: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "100px",
    },
    background: {
      type: Boolean,
      default: false,
    },
    withMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return `
        --min-radius: ${this.size};
        --bg-old: url('/imgs/png/${this.fileName}.png');
        --bg: url('/imgs/webp/${this.fileName}.webp');
        --bg-sm-old: url('/imgs/png/${this.fileName}-sm.png');
        --bg-sm: url('/imgs/webp/${this.fileName}-sm.webp');
      `;
    },
  },
};
</script>

<style></style>
