import en from "./_en";
import es from "./_es";
import pt from "./_pt";
import cn from "./_cn";
import mn from "./_mn";

export default {
  en,
  es,
  pt,
  cn,
  mn,
};
