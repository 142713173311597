<template>
  <!-- Messenger Chat plugin Code -->
  <div id="fb-root"></div>

  <div id="fb-customer-chat" class="fb-customerchat"></div>
  <!-- End Messenger Chat plugin Code -->
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {
  var chatbox = document.getElementById("fb-customer-chat");
  chatbox.setAttribute("page_id", "168273646553234");
  chatbox.setAttribute("attribution", "biz_inbox");

  window.fbAsyncInit = function () {
    // eslint-disable-next-line no-undef
    FB.init({
      xfbml: true,
      version: "v18.0",
    });
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
});
</script>
