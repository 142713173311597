export default {
  certifications: {
    title: "認證",
    text: "NED 学院很自豪能与优秀的机构合作，以保证我们的学生所追求的高质量标准",
  },
  studywithus: {
    header: {
      title: `<span class="text-white">在 NED 学院学习英语并在爱尔兰工作</span>`,
      subtitle: `现在就迈出第一步吧!`,
    },
    form: {
      title: "现在就迈出第一步吧!",
      name: `你的全名`,
      email: `你的郵件`,
      whatsapp: `你的Whatsapp`,
      nationality: `請選擇您的國籍`,
      campus: `您想在哪個校區學習？`,
      button: `獲取報價！`,
    },
    section1: {
      title: `为什么要选择在爱尔兰游学？`,
      items: [
        "合法学习和工作的机会",
        "欧洲最高底薪的国家之一",
        "签证流程简单，抵达爱尔兰后直接获得",
        "有机会去欧洲各个国家旅行",
      ],
    },
    section2: {
      title: `十多年来，NED 学院一直是爱尔兰其一的一所英语学校，并迎来 30 多个不同国家的交换生`,
      subtitle: `我们在全国拥有两个校区，为学生提供非凡的体验并提供卓越的学术教育`,
      button: "我想在NED学院学习",
    },
    section3: {
      dublin: {
        title: "都柏林校区",
        subtitle:
          "位于市中心，乘坐公共交通工具即可轻松抵达，位于两座历史悠久的建筑内，其历史可追溯至 1750 年。",
        button: "我想在都柏林学习",
      },
      limerick: {
        title: "利默里克校区",
        subtitle:
          "酒店位于利默里克市中心，坐落在一栋设施现代化且交通便利的建筑内，可轻松步行前往该市的主要旅游景点。",
        button: "我想在利默里克学习",
      },
    },
    section4: {
      title: "为什么要选择 NED 学院来爱尔兰进行交换项目？",
      items: [
        {
          img: "/landings/landing_0823/imgs/services_01.png",
          text: "欢迎小礼物，见面会和导览",
        },
        {
          img: "/landings/landing_0823/imgs/services_02.png",
          text: "文化沉浸",
        },
        {
          img: "/landings/landing_0823/imgs/services_03.png",
          text: "以您的母语提供支援",
        },
        {
          img: "/landings/landing_0823/imgs/services_04.png",
          text: "活动和旅行",
        },
        {
          img: "/landings/landing_0823/imgs/services_05.jpeg",
          text: "合格的教师",
        },
        {
          img: "/landings/landing_0823/imgs/services_06.jpeg",
          text: "课外活动",
          description: "（会话、发音等）",
        },
        {
          img: "/landings/landing_0823/imgs/services_07.jpeg",
          text: "研讨会和专业课程",
        },
        {
          img: "/landings/landing_0823/imgs/services_08.jpeg",
          text: "爱尔兰的学生顾问",
        },
        {
          img: "/landings/landing_0823/imgs/services_09.jpeg",
          text: "支援多种的付款方式",
        },
        {
          img: "/landings/landing_0823/imgs/services_10.png",
          text: "独家 NED 学院应用程序",
        },
        {
          img: "/landings/landing_0823/imgs/services_11.png",
          text: "享有合作伙伴方的折扣",
        },
      ],
      button: "我想在NED学院学习",
    },
    section5: {
      title: "我们的学生都怎么说？",
      button: "获取报价!",
    },
    section6: {
      button: "我想在NED学院学习",
    },
  },
  studywithus_thanks: {
    title: `感谢您对 NED 学院的兴趣`,
    subtitle: `我们已收到您的信息，将尽快与您联系。`,
    description: `现在您已经迈出了第一步，我们鼓励您更深入地了解我们的英语学校，发掘充满活力的 NED 学生生活。`,
    socials_title: "关注我们的社交平台！",
  },
  financial_plan: {
    title: `通过我们的<span class="text-green3">完整财务规划指南</span>迈出第一步，留学爱尔兰`,
    welcome: {
      title: "欢迎来到留学爱尔兰财务规划指南！",
      subtitle: "梦想在翡翠岛进行交换吗？那么，这本电子书就是为你准备的。",
      text: "我们知道财务方面可能具有挑战性，因此我们创建了一份完整的指南，让您能够实现梦想而无需担心。",
    },
    why: {
      title: "为什么你应该下载这个指南？",
      items: [
        {
          icon: "/landings/financial_plan/icon_1.png",
          text: '明确你的<span class="text-green3">交换目标并理解</span>涉及的成本。',
        },
        {
          icon: "/landings/financial_plan/icon_2.png",
          text: '了解如何根据你的实际情况创建<span class="text-green3">个性化预算</span>。',
        },
        {
          icon: "/landings/financial_plan/icon_3.png",
          text: '学习如何在交换前和交换期间<span class="text-green3">节省和管理你的资金</span>。',
        },
        {
          icon: "/landings/financial_plan/icon_4.png",
          text: '找出如何在交换期间找到<span class="text-green3">工作</span>来帮助你支付费用。',
        },
        {
          icon: "/landings/financial_plan/icon_5.png",
          text: '获取有关如何应对可能出现的<span class="text-green3">突发事件和财务紧急情况</span>的建议。',
        },
      ],
    },
    cta: "立即下载！",
    cta_title:
      '立即下载<span class="text-blue2">留学爱尔兰财务规划指南</span>并开始实现你的交换梦想！',
    footer: {
      campus: "都柏林和利默里克",
    },
    form: {
      title: "填写以下表格以直接收到电子书",
      name: `姓名`,
      email: `电子邮件`,
      whatsapp: `Phone number/WeChat/Line`,
      nationality: `选择您的国籍`,
      campus: `您想在哪个校区学习？`,
      mkt_source: `您是如何了解我们的电子书的？`,
      button: `立即下载我的电子书！`,
    },
    ebook_thumb: "/landings/financial_plan/ebook-thumb-cn.png",
  },
  financial_plan_thanks: {
    title: `感谢下载留学爱尔兰财务规划指南！`,
    first_step: {
      title: '您已经迈出了实现交换梦想的第一步！',
      subtitle: '利用我们的建议，开始准备迎接您一生中最美好的经历。',
      bold: '在这段旅程中，您可以依靠我们的帮助！',
    },
    socials: {
      title: '关注我们学校的日常生活，了解我们学生的生活是怎样的！',
      subtitle: '在社交媒体上关注我们！',
    },
    footer: {
      campus: "都柏林和利默里克",
    },
    ebook_thumb: "/landings/financial_plan/ebook-thumb-cn.png",
  }
};
