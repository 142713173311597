import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ImageContainer from "./components/ImageContainer";
import VideoContainer from "./components/VideoContainer";
import lang from "./utils/lang";
import toast from "./utils/toast";
import routing from "./utils/routing";

// import styles
import "./assets/css/styles.scss";

createApp(App)
  .use(router)
  .use(lang)
  .use(toast)
  .use((app) => {
    app.component("ImageContainer", ImageContainer);
    app.component("VideoContainer", VideoContainer);
    app.mixin(routing);
  })
  .mount("#app");
