export default {
  methods: {
    openTab(name, query = {}) {
      const routeData = this.$router.resolve({ name: name, query });
      window.open(routeData.href, "_blank");
    },
    goTo(name, query = {}) {
      this.$router.push({ name: name, query });
    },
  },
};
