import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: () => {
      const lang = window.localStorage.getItem("lang") || "en";
      return `/${lang}`;
    },
  },
  {
    path: "/:lang?",
    component: () => import("../App.vue"),
    children: [
      {
        path: "",
        component: () => import("../views/WebsiteLayout.vue"),
        children: [
          {
            path: "",
            name: "home",
            component: () => import("../views/Home.vue"),
            meta: {
              title: "Home",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "courses",
            name: "courses",
            component: () => import("../views/Courses.vue"),
            meta: {
              title: "Courses",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "our_campuses/dublin",
            name: "campus_dublin",
            component: () => import("../views/CampusDublin.vue"),
            meta: {
              title: "Campus Dublin",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "our_campuses/limerick",
            name: "campus_limerick",
            component: () => import("../views/CampusLimerick.vue"),
            meta: {
              title: "Campus Limerick",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "about_us/ned_college",
            name: "ned_college",
            component: () => import("../views/NedCollege.vue"),
            meta: {
              title: "About Us",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "about_us/accreditations",
            name: "accreditations",
            component: () => import("../views/Accreditations.vue"),
            meta: {
              title: "Accreditations",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "about_us/privacy_policy",
            name: "privacy_policy",
            component: () => import("../views/PrivacyPolicy.vue"),
            meta: {
              title: "Privacy Policy",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "about_us/terms_and_conditions",
            name: "terms_and_conditions",
            component: () => import("../views/TermsAndConditions.vue"),
            meta: {
              title: "Terms and Conditions",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "student_life/services",
            name: "services",
            component: () => import("../views/Services.vue"),
            meta: {
              title: "Services",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "student_life/student_visa",
            name: "student_visa",
            component: () => import("../views/StudentVisa.vue"),
            meta: {
              title: "Student Visa",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "faqs",
            name: "faqs",
            component: () => import("../views/Faqs.vue"),
            meta: {
              title: "FAQs",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "contact_us",
            name: "contact_us",
            component: () => import("../views/ContactUs.vue"),
            meta: {
              title: "Contact Us",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "careers",
            name: "careers",
            component: () => import("../views/Careers.vue"),
            meta: {
              title: "Careers",
              langs: ["en", "es", "pt"],
            },
          },
          {
            path: "thank_you",
            name: "thank_you",
            component: () => import("../views/ThankYou.vue"),
            meta: {
              title: "Thank you",
              langs: ["en", "es", "pt"],
            },
          },
        ],
      },
      {
        path: ":campus/studywithus",
        name: "studywithus",
        component: () => import("../views/Landing/Landing_0823/Landing_0823.vue"),
        meta: {
          title: "Study with Us",
          langs: ["pt", "en", "es", "cn", "mn"],
        },
      },
      {
        path: "studywithus/thanks",
        name: "studywithus_thanks",
        component: () => import("../views/Landing/Landing_0823/Thanks.vue"),
        meta: {
          title: "Study with Us",
          langs: ["pt", "en", "es", "cn", "mn"],
        },
      },
      {
        path: "partnership",
        name: "partnership",
        component: () => import("../views/Landing/Partnership.vue"),
        meta: {
          title: "Partnership",
          langs: ["en", "es", "pt"],
        },
      },
      {
        path: "ebook-financial-plan",
        name: "financial_plan",
        component: () => import("../views/Landing/FinancialPlan.vue"),
        meta: {
          title: "E-book Financial Plan",
          langs: ["es", "pt", "mn", "cn"],
        },
      },
      {
        path: "ebook-financial-plan/thanks",
        name: "financial_plan_thanks",
        component: () => import("../views/Landing/FinancialPlanThanks.vue"),
        meta: {
          title: "E-book Financial Plan",
          langs: ["es", "pt", "mn", "cn"],
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from) {
    if (to.fullPath === from.fullPath) {
      window.scrollTo(0, 0);
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },
  routes,
});

router.beforeEach((to) => {
  window.scrollTo(0, 0);

  // Set title for each page
  document.title = `NED College - ${to.meta.title}`;

  let lang = to.params.lang || window.localStorage.getItem("lang");

  if (to.path == "/" && window.localStorage.getItem("lang")) {
    lang = window.localStorage.getItem("lang");
  }

  if (!to.meta.langs.includes(lang)) lang = to.meta.langs[0];

  window.localStorage.setItem("lang", lang);

  if (to.params.lang !== lang) {
    return {
      name: to.name,
      params: {
        lang: lang,
      },
      query: to.query,
      hash: to.hash,
    };
  }

  return true;
});

export default router;
